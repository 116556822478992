.tab__component {
    .--buttons {
        display: flex;
        gap: 4rem;
        border-bottom: .1rem solid color(gray, Gray-100);
        button {
            cursor: pointer;
            font-size: 2rem;
            line-height: 5.2rem;
            border-bottom: .2rem solid transparent;
            &.--active {
                border-color: color(base, Secondary);
            }
        }
    }
    .--content {
        padding: 2rem 0;
        > section {
            &:not(:first-child) {
                display: none;
            }
            .responsive__table {
                overflow: auto;
                scroll-snap-type: both mandatory;
                padding-bottom: 2rem;
                table {
                    width: 100%;
                    border-collapse: collapse;
                    tr {
                        th {
                            text-align: left;
                            font-size: 1.6rem;
                            line-height: 5.2rem;
                            border-bottom: .1rem solid color(gray, Gray-100);
                            scroll-snap-align: start;
                            background-clip: padding-box;
                            position: sticky;
                            left: 0;
                            min-width: 15rem;
                            background: color(base, BodyBackground);
                            &::after {
                                content: '';
                                background: color(base, BodyBackground);
                                height: 0.1rem;
                                width: 2rem;
                                position: absolute;
                                top: 100%;
                                right: 0;
                            }
                        }
                        td {
                            text-align: center;
                            font-size: 1.6rem;
                            line-height: 5.2rem;
                            border-bottom: .1rem solid color(gray, Gray-100);
                            scroll-snap-align: start;
                            min-width: 10rem;
                            padding: 0 1rem;
                        }
                    }
                }
            }
        }
    }
}
