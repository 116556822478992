.grid-container .grid-x.grid-padding-x .cell.product__media {
    @include breakpoint(small only) {
        padding: 0;
    }
}

.product__view {
    h1, .product__title {
        text-transform: uppercase;
        font-size: 3.2rem;
        line-height: 3.5rem;
        font-weight: normal;
        display: block;
    }
    @media screen and (min-width: 768px) and (max-width: 1140px) {
        > .grid-x.grid-padding-x {
            > div {
                width: 50%;
            }
        }
    }
    .selected__configuration {
        display: flex;
        align-items: center;
        gap: .5rem;
        flex-wrap: wrap;
        padding-bottom: 12px;
        li {
            display: flex;
            gap: 1rem;
            text-transform: uppercase;
            font-size: 1.6rem;
            line-height: 2rem;
            &:not(:last-child)::after {
                content: '/';
            }
        }
    }
    .product__media {
        position: relative;
        @include breakpoint(small only) {
            margin: 2rem 0;
        }
        .catalog__product_media-gallery {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            position: sticky;
            top: 8rem;
            @include breakpoint(small only) {
                display: none;
            }
            .--image {
                display: flex;
                justify-content: center;
                background: #F4F5FA;
                cursor: zoom-in;
                flex: 0 0 100%;

                > div {
                    width: 100%;

                    .youtube__video {
                        aspect-ratio: 16 / 9;
                        width: 100%;
                    }
                }
            }
            .--actions {
                text-align: center;
                padding: 0 0 2rem;
                width: 100%;
                span {
                    line-height: 2.6rem;
                }
            }
            &.--divide {
                .--image {
                    flex: 0 0 calc(50% - 1rem);
                }
            }
        }
    }
    .catalog__product_media-slider {
        position: fixed;
        width: 100%;
        height: 100%;
        background: color(base, White);
        left: 0;
        top: 0;
        z-index: -1;
        @include breakpoint(small only) {
            position: relative;
            height: 66vw;
            z-index: 1;
            &:not(.open-mobile) {
                img {
                    transform: none;
                }
            }
            &.open-mobile {
                position: fixed;
                height: 100%;
                z-index: 10;
                display: flex;
                align-items: center;
                .swiper-container {
                    width: 100%;
                }
                #close-media-slider {
                    display: block;
                    width: 40px;
                    height: 40px;
                }
            }


        }
        &.active {
            z-index: 10;
        }
        .swiper-slide {
            display: flex;
            height: 100vh;
            @include breakpoint(small only) {
                height: 66vw;
            }
            .panzoom-container {
                flex: 1;
                display: flex;
                position: relative;
                .image__holder {
                    flex: 1;
                    position: relative;
                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }

                    &.--video {
                        display: flex;
                        align-items: center;
                        background: #F4F5FA;

                        .youtube__video {
                            aspect-ratio: 16 / 9;
                            width: 100%;
                        }
                    }
                }
            }
        }

        #close-media-slider {
            width: 60px;
            height: 60px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23ffffff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
            background-color: color(base, Secondary);
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 40px;
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;
            z-index: 2;
            @include breakpoint(small only) {
                display: none;
            }
            &:hover {
                background-color: color(base, SecondaryHover);
            }
        }
        .--swiper-pagination {
            &.panning {
                .next-media-slider,
                .prev-media-slider {
                    display: none;
                }
            }
            .next-media-slider,
            .prev-media-slider {
                position: absolute;
                top: calc(50% - 30px);
                width: 60px;
                height: 60px;
                background-color: rgba(0,023,055,.2);
                background-size: 50%;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 40px;
                cursor: pointer;
                z-index: 2;
                @include breakpoint(small only) {
                    top: calc(50% - 20px);
                    width: 40px;
                    height: 40px;
                }
                &.swiper-button-disabled {
                    display: none;
                }
            }
        }

        .next-media-slider {
            right: 12px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23fff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
        }
        .prev-media-slider {
            left: 12px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23fff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
        }
    }
    .product__information {
        margin: 0 0 2rem 0;

        .short__description {
            margin: 1rem 0 0;
            p, span {
                font-size: 1.4rem !important;
                font-weight: 300 !important;
                line-height: 2.4rem;
            }
        }
        .price__holder-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            position: sticky;
            top: 56px;
            z-index: 3;
            background-color: color(base, White);
            &.--sold-out {
                position: relative;
                top: auto;
            }
            @include breakpoint(medium) {
                gap: 12px;
                align-items: center;
                flex-direction: row;
            }
            .sold-out-add {
                display: flex;
                align-items: baseline;
                gap: 12px;

                span {
                    font-size: 2.4rem;
                    line-height: 1;
                    text-transform: uppercase;
                }

                a {
                    text-decoration: underline;
                }
            }
        }
        .price__holder {
            display: flex;
            margin: 0;
            align-items: center;
            gap: 1.5rem;
            padding: 1.2rem 0;
            > span {
                display: block;
                font-size: 2.4rem;
                line-height: 1;
                color: color(base, Secondary);
                &.--special {
                    color: color(base, Orange);
                }
                &.--old {
                    background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%);
                }
            }
        }

        .family__products {
            margin: 3.5rem 0 0;
            .--attribute {
                &:not(:last-child) {
                    margin: 0 0 2.5rem;
                }
                > span {
                    display: block;
                    margin: 0 0 1rem;
                }
                .--products {
                    display: flex;
                    flex-flow: wrap;
                    gap: 1rem;
                    &.--color {
                        a {
                            background-color: #F4F5FA;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 20%;
                            border: .2rem solid transparent;
                            aspect-ratio: 1;
                            max-width: 7.6rem;
                            &.--active {
                                border-color: color(base, Secondary);
                                pointer-events: none;
                            }
                            @include breakpoint(medium) {
                                &:hover {
                                    border-color: color(gray, Gray-100);
                                }
                            }
                        }
                    }
                    &:not(.--color) {
                        gap: .5rem;
                        a {
                            min-width: 16.666666%;
                            border: .1rem solid color(gray, Gray-200);
                            padding: .1rem;
                            > span > span {
                                padding: 0 1rem;
                                display: block;
                                font-size: 1.4rem;
                                line-height: 4.4rem;
                                font-weight: 500;
                                text-align: center;
                            }
                            &.--active {
                                pointer-events: none;
                                border: .2rem solid color(base, Secondary);
                                padding: 0;
                            }
                            @include breakpoint(medium) {
                                &:hover {
                                    border-color: color(gray, Gray-400);
                                }
                            }
                        }
                    }
                }
            }
        }
        .product__configuration {
            padding: 1.2rem 0;
            position: relative;
            background-color: color(base, White);
            z-index: 2;
            &:not(.--sold-out).sticky_block {
                position: sticky;
                top: 104px;
                .product__option {
                    display: none;
                }
            }
            .product-options__tooltip {
                @include messages('error');
                position: absolute;
                right: calc(100% + 1rem);
                width: 20.5rem;
                font-size: 1.3rem;
                line-height: 1.6rem;
                padding: .8rem 1rem;
                top: 35%;
                transform: translate(0, -50%);
                border-radius: .3rem;
                z-index: 1;
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    margin-top: -.6rem;
                    border-width: .6rem;
                    border-style: solid;
                    border-color: transparent transparent transparent scale-color(color(alert, Error), $lightness: 80%);
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    margin-top: -.5rem;
                    border-width: .5rem;
                    border-style: solid;
                    border-color: transparent transparent transparent scale-color(color(alert, Error), $lightness: 90%);
                }
                @include breakpoint(small only) {
                    position: static;
                    margin: 4rem 0 0;
                    width: 100%;
                    &:before, &:after {
                        display: none;
                    }
                }
            }
            .product__configurable-option__swatch {
                .messages {
                    display: block;
                    margin: .2rem 0 0;
                    height: 2rem;
                    .error {
                        display: block;
                        line-height: 2rem;
                        font-size: 1.3rem;
                        color: color(alert, Error);
                    }
                }
                > span {
                    display: flex;
                    align-items: center;
                    margin: 0 0 1rem;
                    font-size: 1.6rem;
                    gap: .5rem;
                    > span {
                        font-size: 1.6rem;
                        display: block;
                    }
                }
                .swatch__holder {
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                    &.swatch__color .config__options {
                        gap: 1rem;
                        label {
                            width: 20%;
                            border: .2rem solid transparent;
                            &.--active {
                                border-color: color(base, Secondary);
                            }
                        }
                    }
                    &:not(.swatch__color) .config__options {
                        gap: .5rem;
                        label {
                            min-width: 16.666666%;
                            border: .1rem solid color(gray, Gray-200);
                            padding: .1rem;
                            > span > span {
                                padding: 0 1rem;
                                display: block;
                                font-size: 1.4rem;
                                line-height: 4.4rem;
                                font-weight: 500;
                            }
                            &.--active {
                                border: .2rem solid color(base, Secondary);
                                padding: 0;
                            }
                        }
                    }
                }
            }
            .product__bundle {
                dl {
                    margin: 0 0 2rem;
                    dt {
                        font-size: 1.6rem;
                        line-height: 3.7rem;
                        font-weight: 400;
                    }
                    dd {
                        display: flex;
                        align-items: center;
                        gap: 3rem;
                        .config__image {
                            padding: .5rem;
                            background: #f5f4fa;
                            aspect-ratio: 1;
                            object-fit: scale-down;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: .1rem solid color(gray, Gray-100);
                            img {
                                aspect-ratio: 1;
                                width: 7.5rem;
                                height: 7.5rem;
                                object-fit: scale-down;
                            }
                        }
                        .config__options {
                            gap: .5rem;
                            label {
                                min-width: 16.666666%;
                                border: .1rem solid transparent;
                                padding: .1rem;
                                max-height: 3.6rem;
                                > span {
                                    height: 100%;
                                    > span {
                                        padding: 0 1rem;
                                        display: block;
                                        font-size: 1.2rem;
                                        line-height: 3.4rem;
                                    }
                                }
                                &.--active {
                                    border-color: color(base, Secondary);
                                }
                                &.--out-of-stock {
                                    opacity: .4;
                                    cursor: not-allowed;
                                }
                            }
                        }
                    }
                }
            }
            .config__options {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                label {
                    display: block;
                    cursor: pointer;
                    > span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        input {
                            visibility: hidden;
                            position: absolute;
                        }
                        img {
                            aspect-ratio: 1;
                            width: 100%;
                            object-fit: scale-down;
                        }
                    }
                    &.--disabled {
                        cursor: not-allowed;
                        opacity: .6;
                        background: linear-gradient(to top left,
                            rgba(181, 185, 201, 0) 0%,
                            rgba(181, 185, 201, 0) calc(50% - 0.8px),
                            rgba(181, 185, 201, 1) 50%,
                            rgba(181, 185, 201, 0) calc(50% + 0.8px),
                            rgba(181, 185, 201, 0) 100%);
                    }
                }
            }
            .product__configurable-option__select {
                margin: 0 0 2rem;
                display: flex;
                align-items: center;
                select {
                    width: 70%;
                }
            }
            .product__add-to-cart-container {
                display: flex;
                margin: 0 0 0;
                .product__qty {
                    width: 10.2rem;
                    position: relative;
                    display: flex;
                    button {
                        cursor: pointer;
                        position: absolute;
                        font-size: 1.8rem;
                        line-height: 4.8rem;
                        font-weight: 500;
                        &:first-child {
                            left: 1rem;
                        }
                        &:last-child {
                            right: 1rem;
                        }
                        &:disabled {
                            cursor: not-allowed;
                            color: color(gray, Gray-300);
                        }
                        @include breakpoint(medium) {
                            @include transition;
                            &:not(:disabled):hover {
                                color: color(base, SecondaryHover);
                            }
                        }
                    }
                    input {
                        text-align: center;
                        line-height: 4.8rem;
                        padding: 0 2.5rem;
                        height: 4.8rem;
                        font-size: 1.4rem;
                        font-weight: 500;
                        width: 100%;
                    }
                }
                .add-to-cart__holder {
                    margin-left: 1.5rem;
                    flex: 1;
                    button {
                        width: 100%;
                        height: 4.8rem;
                        font-weight: 500;
                        font-size: 1.4rem;
                        &:not(:disabled) {
                            background-color: color(base, Orange);
                            @include breakpoint(medium) {
                                &:hover {
                                    background-color: color(base, OrangeHover);
                                }
                            }
                        }
                        &._cart-loading {
                            background-image: url("data:image/svg+xml,%0A%3C!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL --%3E%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='8.042%25' y1='0%25' x2='65.682%25' y2='23.865%25' id='a'%3E%3Cstop stop-color='#{color(base, White)}' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='#{color(base, White)}' stop-opacity='.631' offset='63.146%25'/%3E%3Cstop stop-color='#{color(base, White)}' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(%23a)' stroke-width='2'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/path%3E%3Ccircle fill='#{color(base, White)}' cx='36' cy='18' r='1'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                            background-repeat: no-repeat;
                            background-position: right .8rem center;
                            background-size: 1.6rem;
                        }
                    }
                }
            }
            .add-to-cart__message {
                height: 2.8rem;
                margin: 1rem 0 0;
            }
        }
        .product__usp {
            margin: 1rem 0 2rem 0;
            ul li {
                font-size: 1.6rem;
                line-height: 3rem;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='20px' viewBox='0 0 26 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='5' points='2 9.4929108 8.5070892 16 23.3947226 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                padding: 0 0 0 2.2rem;
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 1.6rem;
                position: relative;
                #product_infobox_button {
                    width: 20px;
                    height: 20px;
                    background-color: color(gray, Gray-200);
                    display: inline-block;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 20px;
                    color: color(base, White);
                    font-weight: 700;
                    position: relative;
                    left: 6px;
                    top: 7px;
                    cursor: pointer;
                    &:hover {
                        background-color: color(base, SecondaryHover);
                    }
                }
                a {
                    font-size: 1.6rem;
                    line-height: 3rem;
                    @include breakpoint(medium) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            #product_infobox {
                position: fixed;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, .7);
                left: 0;
                top: 0;
                z-index: 11;
                align-items: center;
                justify-content: center;
                display: none;
                &.active {
                    display: flex;
                }
                ._inner {
                    max-width: 600px;
                    width: 90%;
                    background: color(base, White);
                    box-shadow: 0 0 155px rgba(0,0,0,.1);
                    position: relative;
                    #close_infobox {
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 36px;
                        height: 36px;
                        background-color: color(grey, Gray-150);
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23fff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                        background-position: center;
                        background-size: 50%;
                        background-repeat: no-repeat;
                        cursor: pointer;
                        z-index: 1;
                        &:hover {
                            background-color: color(grey, Gray-200);
                        }
                    }
                    ._content {
                        padding: 48px;
                        max-height: 80vh;
                        overflow-y: scroll;
                        &::-webkit-scrollbar {
                            width: 6px;
                        }

                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: color(base, Secondary);
                        }
                        &::before {
                            content: '';
                            width: calc(100% - 6px);
                            height: 48px;
                            background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                        &::after {
                            content: '';
                            width: calc(100% - 6px);
                            height: 48px;
                            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                            position: absolute;
                            left: 0;
                            bottom: 0;
                        }

                        @include breakpoint(small only) {
                            padding: 48px 5vw 5vw 5vw;
                            max-height: calc((100vh - 10vw) - 61px);
                            &::after {
                                height: 5vw;
                            }
                        }
                    }
                }
            }
        }
        .product__detail {
            margin: 4rem 0 0;
            dl {
                border-bottom: .1rem solid color(gray, Gray-200);
                dt {
                    cursor: pointer;
                    font-size: 1.6rem;
                    text-transform: uppercase;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 4.330010) rotate(-270.000000) translate(-5.306354, -4.330010) ' points='2.44757827 -0.169989964 8.16998996 4.3284815 2.44271724 8.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: right center;
                    border-top: .1rem solid color(gray, Gray-200);
                    padding: 1.5rem 0;
                    font-weight: 500;
                    line-height: 2.4rem;
                    &._active {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 5.330010) rotate(-90.000000) translate(-5.306354, -5.330010) ' points='2.44757827 0.830010036 8.16998996 5.3284815 2.44271724 9.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                    }
                }
                dd {
                    display: none;
                    padding: 0 0 2rem 0;
                    &._visible {
                        display: block;
                    }
                    p {
                        font-size: 1.4rem;
                        line-height: 3rem;
                        font-weight: 300 !important;
                        &:not(:last-child) {
                            margin: 0 0 2rem;
                        }
                    }
                    .key__features {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        gap: .5rem;
                        .--feature {
                            text-align: center;
                            .--label {
                                text-transform: uppercase;
                                font-size: 1.2rem;
                                display: block;
                                color: color(gray, Gray-300);
                                margin: 0 0 1rem;
                                font-weight: 500;
                            }
                            .ep-container {
                                .ep-legend--value__counter {
                                    font-size: 1.2rem;
                                    color: color(base, Secondary);
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                    table {
                        width: 100%;
                        tr {
                            border-bottom: .1rem solid color(gray, Gray-100);
                            th {
                                text-transform: uppercase;
                                font-size: 1.2rem;
                                color: color(gray, Gray-200);
                                padding: .2rem 0;
                                font-weight: 500;
                            }
                            td {
                                font-size: 1.2rem;
                                text-align: center;
                                padding: .2rem 0;
                            }
                        }
                    }
                    ul {
                        li {
                            font-size: 1.4rem;
                            display: flex;
                            align-items: center;
                            > span {
                                display: block;
                                line-height: 1.4;
                                padding: 1rem;
                                &:first-child {
                                    flex: 0 0 40%;
                                    font-weight: 500;
                                }
                            }
                            &:nth-child(even) {
                                background: color(gray, Gray-150);
                            }
                        }
                    }
                    .--content {
                        b, strong {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
    .page-builder__wrapper {
        margin: 5rem 0 0;
    }
    .foil__specifications {
        margin: 4rem 0 0;
        @include breakpoint(medium) {
            margin: 8rem 0 0;
        }
        h2 {
            @include default-title-element;
        }
    }
}

.product-media-info {
    .--images {
        gap: 2rem;
        @include breakpoint(medium) {
            gap: 3rem;
        }

        .--item {
            cursor: zoom-in;
            flex: 0 0 calc(50% - 10px);
            @include breakpoint(medium) {
                flex: 0 0 calc(50% - 15px);
            }
        }
    }
}

.product-media-slider {
    .grid-container {
        position: relative;
        &.slider-container {
            @include breakpoint(medium) {
                padding: 0 60px 0 30px;
            }

            .image__container {
                cursor: zoom-in;
            }
        }

        .next-media-slider,
        .prev-media-slider {
            position: absolute;
            top: calc(50% - 15px);
            width: 30px;
            height: 30px;
            background-color: rgba(0,023,055,.2);
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 40px;
            cursor: pointer;
            z-index: 2;
            &.swiper-button-disabled {
                display: none;
            }
        }

        .next-media-slider {
            right: 10px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23fff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
        }
        .prev-media-slider {
            left: 10px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23fff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
        }
    }

    .--footer {
        text-align: right;

        button {
            font-size: 1.6rem;
            line-height: 3rem;
            text-decoration: underline;
            cursor: pointer;
            margin-right: 50px;

            @include breakpoint(small only) {
                margin-right: 0;
            }
        }
    }
}

#solar-app .catalog__product_media-slider.--bottom,
#solar-app .catalog__product_media-info-slider {
    position: fixed;
    width: 100%;
    height: 100%;
    background: color(base, White);
    left: 0;
    top: 0;
    z-index: -1;
    &.active {
        z-index: 10;
    }
    .swiper-slide {
        margin: 0;
        display: flex;
        height: 100vh;
        width: 100%;
        @include breakpoint(small only) {
            height: 66vw;
        }
        .panzoom-container {
            flex: 1;
            display: flex;
            position: relative;
            .image__holder {
                flex: 1;
                position: relative;
                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    #close-media-slider {
        width: 60px;
        height: 60px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23ffffff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
        background-color: color(base, Secondary);
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 40px;
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
        z-index: 2;
        &:hover {
            background-color: color(base, SecondaryHover);
        }
        @include breakpoint(small only) {
            width: 40px;
            height: 40px;
            top: auto;
            bottom: 12px;
            right: calc(50% - 20px);
        }
    }
    .--swiper-pagination {
        &.panning {
            .next-media-slider,
            .prev-media-slider {
                display: none;
            }
        }
        .next-media-slider,
        .prev-media-slider {
            position: absolute;
            top: calc(50% - 30px);
            width: 60px;
            height: 60px;
            background-color: rgba(0,023,055,.2);
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 40px;
            cursor: pointer;
            z-index: 2;
            @include breakpoint(small only) {
                top: calc(50% - 20px);
                width: 40px;
                height: 40px;
            }
            &.swiper-button-disabled {
                display: none;
            }
        }
    }

    .next-media-slider {
        right: 12px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23fff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
    }
    .prev-media-slider {
        left: 12px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23fff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
    }
}
