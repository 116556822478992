@use "sass:math";

@mixin letter-spacing {
    letter-spacing: .2rem;
}

@mixin text-shadow {
    text-shadow: 0 3rem 3.465rem rgba(0, 0, 0, .36);
}

@mixin default-button {
    @include transition;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: color(base, Primary);
    color: color(base, White);
    font-family: $default-font;
    height: 3.6rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    line-height: normal;
    padding: .75rem 3rem;
    cursor: pointer;
    font-weight: 500;
    @include breakpoint(medium) {
        font-size: 1.2rem;
        padding: 1rem 2rem;
        &:hover {
            background-color: color(base, PrimaryHover);
        }
    }
    &.--min-width {
        min-width: 16rem;
        @include breakpoint(medium) {
            min-width: 18rem;
        }
    }
    &.--cta {
        background-color: color(base, CallToAction);
        @include breakpoint(medium) {
            &:hover {
                background-color: color(base, CallToActionHover);
            }
        }
    }
    &.--transparent {
        background-color: transparent;
        @include breakpoint(medium) {
            &:hover {
                background-color: transparent;
                text-decoration: underline;
            }
        }
    }
    &.--small {
        font-size: 1.3rem;
        padding: .75rem 1rem;
        border-radius: 1.3rem;
    }
    &:disabled {
        background-color: color(gray, Gray-300);
        color: color(base, White);
        cursor: not-allowed;
        @include breakpoint(medium) {
            &:hover {
                background: color(gray, Gray-300);
                color: color(base, White);
            }
        }
    }
}

@mixin search-input {
    position: relative;
    input {
        background: transparent;
        border-radius: 0;
        padding: 0 4rem 0 0;
        border: none;
        border-bottom: .1rem solid color(gray, Gray-100);
        line-height: 3.2rem;
        height: 3.2rem;
        font-size: 1.4rem;
        margin-top: 0.8rem;
        text-transform: uppercase;
    }
    button {
        position: absolute;
        width: 3rem;
        height: 3rem;
        right: 0;
        top: .5rem;
        bottom: .5rem;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='21px' viewBox='0 0 20 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-5' transform='translate(1.000000, 1.000000)' stroke='#{color(base, Secondary)}' stroke-width='1.5'%3E%3Cpath d='M14.77,7.385 C14.77,11.464 11.464,14.77 7.385,14.77 C3.306,14.77 0,11.464 0,7.385 C0,3.306 3.306,0 7.385,0 C11.464,0 14.77,3.306 14.77,7.385 Z' id='Stroke-1'%3E%3C/path%3E%3Cline x1='17.8084' y1='19.2332' x2='11.9594' y2='13.3842' id='Stroke-3'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
    }
}

@mixin default-title-element {
    color: color(base, Secondary);
    text-align: center;
    font-size: 3.6rem;
    align-items: baseline;
    margin: 0 0 3rem;
    line-height: 1;
    font-family: $grotesk-font;
    @include breakpoint(medium) {
        margin: 0 0 4rem;
    }
}

@mixin default-title {
    h2, h3 {
        @include default-title-element;
    }
    a {
        display: none;
        @include head-font;
        font-size: 1.4rem;
        margin: .5rem 0 0;
        text-decoration: underline;
        @include breakpoint(medium) {
            font-size: 1.8rem;
            &:hover {
                color: color(base, Black);
            }
        }
    }
}

@mixin autocomplete {
    margin: 0 0 2rem;
    .resolved__address {
        span {
            display: block;
            font-weight: normal;
            font-size: 1.3rem;
            &._error {
                color: color(alert, Error);
            }
        }
        label {
            font-size: 1.4rem;
            cursor: pointer;
            text-decoration: underline;
        }
        address {
            background: color(gray, Gray-150);
            display: inline-flex;
            padding: 1.5rem;
            min-width: 40%;
            font-size: 1.4rem;
            @include breakpoint(small only) {
                width: 100%;
            }
        }
    }
}

@mixin alternative-button {
    @include transition;
    display: block;
    border: .2rem solid color(base, Secondary);
    text-transform: uppercase;
    font-size: 1.2rem;
    height: 4.4rem;
    line-height: 4.2rem;
    text-align: center;
    color: color(base, Secondary);
    background-position: right 1.5rem center;
    background-repeat: no-repeat;
    font-weight: 500;
    @include breakpoint(medium) {
        font-size: 1.4rem;
        height: 4.8rem;
        line-height: 4.4rem;
        &:not(:disabled):hover {
            background-color: rgba(17, 0, 71, .1);
        }
    }
    &.--loading-cart {
        background-image: url("data:image/svg+xml,%0A%3C!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL --%3E%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='8.042%25' y1='0%25' x2='65.682%25' y2='23.865%25' id='a'%3E%3Cstop stop-color='#{color(base, Secondary)}' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='#{color(base, Secondary)}' stop-opacity='.631' offset='63.146%25'/%3E%3Cstop stop-color='#{color(base, Secondary)}' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(%23a)' stroke-width='2'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/path%3E%3Ccircle fill='#{color(base, Secondary)}' cx='36' cy='18' r='1'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        background-size: 2rem;
    }
    &.--added-cart {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='20px' viewBox='0 0 26 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(alert, Success)}' stroke-width='5' points='2 9.4929108 8.5070892 16 23.3947226 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        background-size: 1.6rem;
    }
    &:disabled {
        cursor: not-allowed;
        background-color: color(gray, Gray-100);
    }
}
