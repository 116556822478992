._pagination {
    > span {
        display: none;
    }
    ul {
        display: flex;
        justify-content: center;
        li {
            ._button {
                cursor: pointer;
                display: block;
                width: 2.8rem;
                height: 2.8rem;
                line-height: 2.4rem;
                font-size: 1.2rem;
                margin: 0 .5rem;
                text-align: center;
                background-repeat: no-repeat;
                background-position: center;
                background-color: transparent;
                padding: 0 .3rem 0 .3rem;
                color: color(base, Secondary);
                border: .1rem solid color(base, Secondary);
                @include breakpoint(medium) {
                    &:not(._current):hover {
                        text-decoration: underline;
                    }
                }
                &._next {
                    background-color: transparent;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='13px' viewBox='0 0 9 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolyline id='Stroke-1' stroke='%23110047' stroke-width='3' transform='translate(4.000000, 6.500000) rotate(-90.000000) translate(-4.000000, -6.500000) ' points='-1 4.00509061 3.9974534 9 9 4'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                    border: none;
                }
                &._prev {
                    background-color: transparent;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='13px' viewBox='0 0 9 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolyline id='Stroke-1' stroke='%23110047' stroke-width='3' transform='translate(5.000000, 6.500000) rotate(-270.000000) translate(-5.000000, -6.500000) ' points='2.84217094e-14 4.00509061 4.9974534 9 10 4'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                    border: none;
                }
                &._current {
                    cursor: default;
                    pointer-events: none;
                    background-color: color(base, Secondary);
                    color: color(base, White);
                }
            }
        }
    }
}
