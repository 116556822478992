.slider__control {
    @media screen and (max-width: 1388px) {
        display: none;
    }
    @include transition;
    display: block;
    width: 2rem;
    height: 4rem;
    position: absolute;
    top: 30%;
    z-index: 8;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    &._prev {
        left: -4rem;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='40px' viewBox='0 0 20 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2.748' points='18 38 2 19.7557264 18 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        &:hover {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='40px' viewBox='0 0 20 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, SecondaryHover)}' stroke-width='2.748' points='18 38 2 19.7557264 18 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        }
    }
    &._next {
        right: -4rem;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='40px' viewBox='0 0 20 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2.748' transform='translate(10.000000, 20.000000) scale(-1, 1) translate(-10.000000, -20.000000) ' points='18 38 2 19.7557264 18 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        &:hover {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='40px' viewBox='0 0 20 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, SecondaryHover)}' stroke-width='2.748' transform='translate(10.000000, 20.000000) scale(-1, 1) translate(-10.000000, -20.000000) ' points='18 38 2 19.7557264 18 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        }
    }
}

.swiper-pagination-progressbar {
    width: auto;
    position: relative;
    height: .1rem;
    background: color(gray, Gray-200);
    margin: 3rem 2rem 0 0;
    .swiper-pagination-progressbar-fill {
        background: color(gray, Gray-200);
        height: 1rem;
        border-radius: .5rem;
        top: -.4rem;
    }
}

.swiper__pagination {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    button {
        width: 0.9rem;
        height: 1.4rem;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        &.--prev {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(4.945500, 6.888000) rotate(-180.000000) translate(-4.945500, -6.888000) ' points='2.005 1 7.891 6.886 2 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
            @include breakpoint(medium) {
                &:not(.swiper-button-disabled):hover {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, SecondaryHover)}' stroke-width='2' transform='translate(4.945500, 6.888000) rotate(-180.000000) translate(-4.945500, -6.888000) ' points='2.005 1 7.891 6.886 2 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                }
            }
            &.swiper-button-disabled {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(gray, Gray-100)}' stroke-width='2' transform='translate(4.945500, 6.888000) rotate(-180.000000) translate(-4.945500, -6.888000) ' points='2.005 1 7.891 6.886 2 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                cursor: default;
            }
        }
        &.--next {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
            @include breakpoint(medium) {
                &:not(.swiper-button-disabled):hover {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, SecondaryHover)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                }
            }
            &.swiper-button-disabled {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(gray, Gray-100)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                cursor: default;
            }
        }
    }
}

.swiper-pagination {
    position: inherit;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 2rem 0 0;
    .swiper-pagination-bullet {
        opacity: 1;
        background: transparent;
        border: .1rem solid color(base, Secondary);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        &.swiper-pagination-bullet-active {
            background-color: color(base, Primary);
            border-color: color(base, Primary);
        }
    }
}

.blaze-slider {
    .blaze-pagination {
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin: 1rem 0 0;

        button {
            text-indent: -999rem;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background: transparent;
            border: .1rem solid color(base, Secondary);

            &.active {
                background: color(base, Primary);
                border: .1rem solid transparent;
            }
        }
    }

    &.static {
        .blaze-pagination, .blaze-next, .blaze-prev {
            display: none;
        }
    }
}
