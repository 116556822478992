.breadcrumbs {
    overflow: hidden;
    padding: 1rem 0;
    @include breakpoint(medium) {
        padding: 2.5rem 0;
    }
    ol {
        display: flex;
        align-items: center;
        li {
            display: flex;
            align-items: center;
            a, span {
                display: block;
                text-transform: uppercase;
                font-size: 1.2rem;
                white-space: nowrap;
                color: color(gray, Gray-500);
                line-height: 3rem;
            }
            a:hover {
                text-decoration: underline;
            }
            ._sep {
                display: block;
                margin: 0 .3rem;
            }
        }
    }
}
