.blog__page {
    .breadcrumbs {
        margin: 0 0 2rem;
    }
    .blog__navigation {
        margin: 2rem 0;
        @include breakpoint(medium) {
            background: color(gray, Gray-100);
            padding: 1.5rem;
            max-width: 90%;
            margin: 0 0 2rem;
        }
        h3 {
            margin: 0 0 1rem;
        }
        ul li {
            @include breakpoint(medium) {
                a {
                    font-size: 1.4rem;
                    display: block;
                    line-height: 2.8rem;
                    color: color(base, Black);
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            @include breakpoint(small only) {
                font-size: .8125rem;
                line-height: 4.8rem;
                padding: 0 .9375em;
                border-bottom: .1rem solid color(gray, Gray-100);
            }
            &._active {
                a {
                    font-weight: 700;
                    pointer-events: none;
                }
            }
        }
        &._recent {
            ul li a {
                line-height: normal;
                padding: .5rem 0;
            }
        }
    }
    .blog__item {
        @include transition;
        display: block;
        margin: 0 0 2rem;
        padding: 1rem;
        background: color(gray, Gray-100);
        text-align: center;
        @include breakpoint(medium) {
            &:hover {
                box-shadow: .15rem .15rem .5rem 0.03rem rgb(0 0 0 / 15%);
                transform: translate(.5rem, -.5rem);
            }
        }
        .image__holder {
            display: block;
            margin: 0 0 1rem;
            img {
                object-fit: scale-down;
                aspect-ratio: 65/42;
                margin: auto;
                width: 100%;
                height: 100%;
            }
        }
        ._content {
            h2 {
                font-size: 1.5rem;
                line-height: normal;
                text-transform: uppercase;
                margin: 0 0 1rem;
            }
            span {
                font-size: 1.2rem;
                font-style: italic;
            }
        }
    }
    .hero__image {
        position: fixed;
        inset: 0 0 0 0;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: cover;
        @include breakpoint(small only) {
            display: none;
        }
    }
    @include breakpoint(medium) {
        .hero__image + .grid-container {
            background: color(base, White);
            margin-top: 5rem;
            border-radius: .5rem;
        }
    }
    h1 {
        font-size: 2.2rem;
        line-height: 2.8rem;
        text-transform: uppercase;
        margin: 0 0 2rem;
    }
    .blog__post-index {
        ._header {
            margin: 0 0 1.5rem;
            @include breakpoint(small only) {
                margin: 2rem 0 1.5rem;
            }
            h1 {
                margin: 0;
            }
            ._date {
                font-size: 1.2rem;
                font-style: italic;
                display: block;
            }
        }
        h2 {
            font-size: 2rem;
            margin: 0 0 1.5rem;
        }
        h3 {
            font-size: 1.8rem;
            margin: 0 0 1.5rem;
        }
        p {
            margin: 0 0 1.5rem;
        }
        a {
            text-decoration: underline;
            &:hover {
                color: color(base, SecondaryHover);
            }
        }
        ul {
            margin: 0 0 1.5rem 2rem;
            list-style: outside disc;
            li {
                font-size: 1.4rem;
                line-height: 2.1rem;
            }
        }
        ._tags {
            border-top: .1rem solid color(gray, Gray-100);
            padding: 2rem 0;
            margin: 2rem 0;
            display: flex;
            align-items: center;
            span {
                display: block;
                font-weight: 700;
                font-size: 1.2rem;
                text-transform: uppercase;
                margin-right: .5rem;
            }
            ul {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                margin: 0;
                li {
                    pointer-events: none;
                    margin: .5rem;
                    font-size: 1.2rem;
                    background: color(gray, Gray-200);
                    color: color(base, Secondary);
                }
            }
        }
    }
}
