.checkout-cart_body {
    &.--checkout header {
        .menu__toggle, .header__nav, .right__menu > div {
            display: none;
        }
        .--logo {
            margin: 0 auto;
        }
        .header--right {
            @include breakpoint(medium) {
                display: none;
            }
            @include breakpoint(small only) {
                flex: 0 0 7rem;
                .right__menu {
                    display: none;
                }
            }
        }
    }
    .header__nav {
        display: none;
    }
    .checkout-cart {
        .progress-bar {
            display: flex;
            gap: 1rem;
            margin: 2rem 0;
            li {
                flex: 1;
                span {
                    display: block;
                    background-color: color(gray, Gray-100);
                    padding: 0 .8rem;
                    font-size: 1.1rem;
                    line-height: 4rem;
                    text-transform: uppercase;
                    font-weight: 500;
                    background-repeat: no-repeat;
                    background-position: right .5rem center;
                    @include breakpoint(medium) {
                        padding: 0 2rem;
                        font-size: 1.3rem;
                        background-position: right 1.5rem center;
                    }
                }
                &:not(:last-child):not(.--active) {
                    span {
                        cursor: pointer;
                    }
                }
                &.--active {
                    span {
                        background-color: color(base, Secondary);
                        color: color(base, White);
                        @include breakpoint(medium) {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, White)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                        }
                    }
                }
                &.--complete {
                    span {
                        background-color: color(alert, Success);
                        color: color(base, White);
                        @include breakpoint(medium) {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");
                            background-size: 2rem;
                        }
                    }
                }
            }
        }
        > .grid-container {
            max-width: 128rem;
        }
        ._header ._holder {
            h1 {
                line-height: 2.7rem;
            }
            ._back {
                @include breakpoint(small only) {
                    position: absolute;
                    top: 4.5rem;
                    width: 3.2rem;
                    height: 3.2rem;
                }
                button, a {
                    cursor: pointer;
                    display: block;
                    width: 14px;
                    height: 27px;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='14px' height='27px' viewBox='0 0 14 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Black)}' stroke-width='3.1' points='12 26 2 13.3307805 12 1'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                    margin-right: 1.5rem;
                    @include breakpoint(medium) {
                        @include transition;
                        &:hover {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='14px' height='27px' viewBox='0 0 14 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, SecondaryHover)}' stroke-width='3.1' points='12 26 2 13.3307805 12 1'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                        }
                    }
                    @include breakpoint(small only) {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' width='10px' height='16px' viewBox='0 0 10.0 16.0' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3CclipPath id='56117117984956111112120108'%3E%3Cpath d='M7.54602459,0.248185264 C7.90545864,-0.0827284212 8.45859055,-0.0827284212 8.81802459,0.248185264 L9.66802459,1.05118526 C9.83584143,1.20521912 9.9315358,1.42245567 9.93191691,1.65024675 C9.93229574,1.87803782 9.83732663,2.09559241 9.67002459,2.25018526 L3.89702459,7.72418526 L9.66902459,13.1991853 C9.83645509,13.3536385 9.93160535,13.5711135 9.93141537,13.7989045 C9.93122538,14.0266955 9.8357125,14.2440115 9.66802459,14.3981853 L8.81802459,15.2001853 C8.45896623,15.5320603 7.90508295,15.5320603 7.54602459,15.2001853 L0.263024594,8.32418526 C0.0953637747,8.16951421 0,7.95179332 0,7.72368526 C0,7.4955772 0.0953637747,7.27785632 0.263024594,7.12318526 Z'%3E%3C/path%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(0.0004094530524909601 0.0)'%3E%3Cg clip-path='url(%2356117117984956111112120108)'%3E%3Cpolygon points='0,-2.77555756e-17 9.93191803,-2.77555756e-17 9.93191803,15.4490915 0,15.4490915 0,-2.77555756e-17' stroke='none' fill='%232F2F2F'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        background-position: center;
                        background-repeat: no-repeat;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .checkout-error-container {
            margin: 0 0 1rem;
        }
        .login__block {
            font-size: 1.4rem;
            button {
                cursor: pointer;
                text-decoration: underline;
            }
        }
        .form-field-container {
            @include breakpoint(medium) {
                width: 75%;
            }
        }
        .field__group {
            @include breakpoint(medium) {
                &.names {
                    > div {
                        flex: 1;
                    }
                }
            }
            .checkout__field {
                flex: 1;
                input {
                    &.failed {
                        border-color: color(alert, Error);
                    }
                    &.valid {
                        border-color: color(alert, Success);
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='6.13132462 8.81921546 3.04945992 5.73735077 2 6.77942013 6.13132462 10.9107447 15 2.04206936 13.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23178038' stroke-width='1.5' fill='%23178038' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-position: right 10px center;
                        background-size: 12px;
                    }
                }
            }
            margin: 2.5rem -.5rem 1rem;
            display: flex;
            > div {
                margin: 0 .5rem;
            }
        }
        .shipping__address, .billing__address {
            h2 {
                border-top: .1rem solid color(gray, Gray-100);
                text-transform: uppercase;
                font-size: 1.8rem;
                line-height: normal;
                padding: 2rem 0 1rem;
                font-weight: normal;
            }
            .shipping-address-select {
                margin: 0 0 3rem;
                label {
                    margin: 0 0 1rem;
                }
                @include breakpoint(medium) {
                    select {
                        width: 65%;
                        padding-right: 3rem;
                    }
                }
            }
            .checkout__address-autocomplete_result {
                @include autocomplete;
            }
            .billing-same-as-shipping-control {
                display: flex;
                align-items: center;
                label:not(.vue-js-switch) {
                    margin-left: 1rem;
                }
            }
        }
        .billing__address {
            .shipping-address-select {
                margin: 3rem 0;
            }
        }
        .checkout-footer {
            margin: 3rem 0;
            ._place-order {
                display: grid;
                grid-auto-columns: max-content;
                a {
                    font-size: 1.2rem;
                    margin: .5rem 0 0;
                    span {
                        font-size: 1.2rem;
                        text-decoration: underline;
                        font-weight: normal;
                    }
                }
            }
            button {
                min-width: 40%;
                font-weight: 500;
                font-size: 1.4rem;
                @include breakpoint(small only) {
                    width: 100%;
                }
            }
        }
        .checkout-footer, .cart__side-bar-container .cart__side-bar ._actions {
            button {
                width: 100%;
                height: 4.8rem;
                font-weight: 500;
                font-size: 1.4rem;
                &:not(:disabled) {
                    background-color: color(base, Orange);
                    @include breakpoint(medium) {
                        &:hover {
                            background-color: color(base, OrangeHover);
                        }
                    }
                }
            }
        }
        .checkout-footer button {
            width: auto;
        }
        .shipping__methods, .payment__methods {
            position: relative;
            padding: 2rem 0 0;
            h2 {
                text-transform: uppercase;
                font-size: 1.8rem;
                line-height: normal;
                margin: 0 0 3rem;
                font-weight: normal;
            }
            ._loading {
                position: static;
                background: transparent;
                ._spinner {
                    margin: 2rem 0;
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
            .shipping__methods-list, .payment__methods-list {
                @include breakpoint(medium) {
                    width: 70%;
                }
                .shipping__method, .payment__method {
                    input {
                        position: absolute;
                        visibility: hidden;
                        &:checked + label {
                            background-color: color(gray, Gray-150);
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='6.13132462 8.81921546 3.04945992 5.73735077 2 6.77942013 6.13132462 10.9107447 15 2.04206936 13.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23178038' stroke-width='1.5' fill='%23178038' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: right 2rem center;
                        }
                    }
                    label {
                        display: flex;
                        align-items: center;
                        border: .1rem solid color(gray, Gray-100);
                        padding: 1rem;
                        cursor: pointer;
                        margin: 2rem 0;
                        min-height: 5rem;
                        img {
                            aspect-ratio: 8/7;
                            margin-right: 1rem;
                        }
                        span._title {
                            display: block;
                            font-weight: normal;
                            font-size: 1.6rem;
                        }
                        span._fee {
                            display: block;
                            font-weight: normal;
                            color: color(gray, Gray-300);
                            margin-left: .5rem;
                            font-size: 1.2rem;
                            line-height: 1.6rem;
                        }
                        span._sub {
                            margin-left: 2rem;
                        }
                        @include breakpoint(medium) {
                            @include transition;
                            &:hover {
                                border-color: color(gray, Gray-200);
                            }
                        }
                    }
                }
            }
            .payment__method__options {
                border-bottom: .2rem solid color(gray, Gray-100);
                padding: 0 0 2rem;
            }
        }
        .address__summary-container {
            display: flex;
            flex-wrap: wrap;
            border-bottom: .2rem solid color(gray, Gray-100);
            padding: 0 0 3rem;
            @include breakpoint(small only) {
                padding: 1.5rem 0 2rem;
            }
            .address__summary {
                flex: 1;
                h4 {
                    text-transform: uppercase;
                    font-size: 1.6rem;
                }
                ul {
                    li {
                        line-height: 2.2rem;
                        font-size: 1.4rem;
                    }
                }
            }
            button {
                cursor: pointer;
                width: 100%;
                text-align: left;
                margin: 2rem 0 0;
                text-decoration: underline;
                font-size: 1.4rem;
            }
        }

        .catalog__category-list {
            > div {
                display: flex;
                align-items: center;
                margin: 0 0 2rem;
                gap: 2rem;

                h3 {
                    font-size: 2rem;

                    @include breakpoint(medium) {
                        font-size: 2.4rem;
                    }
                }

                .swiper__pagination {
                    margin-left: auto;
                }
            }
        }
    }
    .cart__side-bar-container {
        .payment__methods {
            p {
                display: flex;
            }
            img {
                margin-right: .5rem;
            }
        }
    }
    .checkout-footer, ._actions {
        button._cart-loading {
            background-image: url("data:image/svg+xml,%0A%3C!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL --%3E%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='8.042%25' y1='0%25' x2='65.682%25' y2='23.865%25' id='a'%3E%3Cstop stop-color='#{color(base, White)}' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='#{color(base, White)}' stop-opacity='.631' offset='63.146%25'/%3E%3Cstop stop-color='#{color(base, White)}' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(%23a)' stroke-width='2'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/path%3E%3Ccircle fill='#{color(base, White)}' cx='36' cy='18' r='1'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: right 1.5rem center;
            background-size: 2rem;
        }
    }
    .checkout__newsletter {
        margin: 3rem 0 0;
        padding: 3rem 0 0;
        border-top: .1rem solid color(gray, Gray-100);
        display: flex;
        align-items: center;
        @include breakpoint(medium) {
            width: 70%;
        }
        label:last-child {
            margin-left: 1rem;
        }
    }
    .vfl-has-label {
        .vfl-label-on-input {
            color: color(base, DefaultFontColor);
            top: -2rem;
        }
    }
}
