.modal__popup_overlay {
    background: rgba(179, 179, 179, .66);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 85;
}
.modal__popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 88;
    .modal__popup_content {
        @include shadow();
        background: color(base, White);
        min-width: 30%;
        .modal__header {
            padding: 1rem 2rem;
            background: color(base, Secondary);
            display: flex;
            ._title {
                color: color(base, White);
                text-transform: uppercase;
            }
            button {
                margin-left: auto;
                width: 2.1rem;
                height: 2.1rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(base, White)}'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                @include breakpoint(medium) {
                    @include transition;
                    &:hover {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(gray, Gray-100)}'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                    }
                }
            }
        }
        .modal__content {
            padding: 2rem;
            .modal__field {
                margin: 1rem 0 2.5rem;
                @include breakpoint(medium) {
                    width: 70%;
                }
            }
            ._actions {
                button {
                    text-decoration: none;
                    margin-right: 1rem;
                }
            }
        }
    }
}
