input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::placeholder {
    color: color(gray, Gray-400);
    opacity: 1;
}

:-ms-input-placeholder {
    color: color(gray, Gray-400);
}

::-ms-input-placeholder {
    color: color(gray, Gray-400);
}

input[type='number'] {
    -moz-appearance: textfield;
}

label {
    font-size: 1.4rem;
}

input[type='radio'] {
    appearance: inherit;
    -webkit-appearance: radio;
}

input[type='checkbox'] {
    appearance: inherit;
    -webkit-appearance: checkbox;
}

input[type='text'], input[type='search'], input[type='tel'], input[type='email'], input[type='number'], input[type='password'], select {
    background: color(base, BodyBackground);
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.3rem;
    padding: 0 1.6rem;
    border: .1rem solid color(gray, Gray-200);
    color: color(base, Secondary);
    font-weight: normal;
    &:disabled {
        cursor: not-allowed;
        background: color(base, BodyBackground);
        opacity: 1;
    }
    @include breakpoint(medium) {
        line-height: 4.6rem;
        height: 4.6rem;
        font-size: 1.5rem;
    }
}

textarea {
    line-height: normal;
    min-height: 18rem;
    font-size: 1.4rem;
    padding: 1rem 1.5rem;
    border: .1rem solid color(gray, Gray-200);
    font-weight: normal;
    color: color(base, Secondary);
    @include breakpoint(medium) {
        font-size: 1.6rem;
    }
}

select {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 4.330010) rotate(-270.000000) translate(-5.306354, -4.330010) ' points='2.44757827 -0.169989964 8.16998996 4.3284815 2.44271724 8.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
    background-size: initial;
    background-position: right 1.5rem center;
    background-repeat: no-repeat;
    padding: 0 3.5rem 0 2rem;
    font-size: 1.4rem;
    font-weight: normal;
    option {
        font-size: 1.4rem;
    }
}

.field-error {
    font-size: 1.2rem;
    display: block;
    margin-top: .5rem;
    font-weight: normal;
    color: color(alert, Error);
}

.field-success {
    font-size: 1.2rem;
    display: block;
    margin-top: .5rem;
    font-weight: normal;
    color: color(alert, Success);
}

.field__container {
    margin: 0 0 2rem;
    label {
        color: color(base, Secondary);
        font-size: 1.2rem;
        margin: 0 0 1rem;
        text-transform: uppercase;
    }
    .input__group {
        display: flex;
        padding: 1rem 0 0;
        label {
            margin-right: 1rem;
            font-size: 1.4rem;
            cursor: pointer;
        }
        input {
            width: auto;
            margin-right: .5rem;
        }
    }
    &.flex {
        label {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-right: 1rem;
            input {
                margin-right: .5rem;
            }
        }
    }
    &.--radio {
        align-items: center;
        gap: .5rem;
        margin: 0 0 1rem;
        label {
            margin: 0 .5rem 0 0;
            font-size: 1.2rem;
        }
    }
}
