.page-builder__wrapper {
    .simple-text {
        .grid-container {
            @include breakpoint(medium) {
                max-width: 90rem;
                margin: 0 auto;
            }
        }

        p {
            font-size: 2rem;
            line-height: 3.2rem;
        }

        .cta_button {
            margin-top: 2rem;
        }
    }
    .two-column-block {
        .--header {
            @include default-title;
        }
        .banner-block {
            margin: 0 0 4rem;
            h3 {
                text-transform: uppercase;
                font-size: 2.4rem;
                line-height: 3.8rem;
                display: block;
                margin: 1rem 0 .5rem;
            }
            p {
                line-height: 3rem;
                font-size: 1.5rem;
                @include breakpoint(medium) {
                    font-size: 1.8rem;
                }
            }
            .--actions {
                a {
                    @include alternative-button;
                    margin: 1.5rem 0 0;
                }
            }
        }
    }
    .brand-content-block {
        .--header {
            @include default-title;
        }
        @include breakpoint(small only) {
            picture {
                display: block;
                margin: 3rem 0 0;
            }
        }
        .content__container {
            display: flex;
            flex-flow: column;
            height: 100%;
            .--content {
                flex: 1;
                line-height: 3rem;
                font-size: 1.8rem;
                p {
                    line-height: 3rem;
                    font-size: 1.8rem;
                    &:not(:last-of-type) {
                        margin: 0 0 4rem;
                    }
                }
            }
            .--actions {
                margin-bottom: auto;
                a {
                    @include alternative-button;
                    margin: 1.5rem 0 0;
                }
            }
        }
    }
    .blog-block {
        .blog-block-container {
            max-width: 192rem;
            margin: 0 auto;
            position: relative;
            > .grid-container {
                position: relative;
            }
            .swiper__pagination {
                position: absolute;
                top: 1rem;
                right: 1rem;
            }
            .--header {
                @include default-title;
            }
            @include breakpoint(small only) {
                .swiper-container {
                    margin-left: 1rem;
                    .swiper-slide {
                        width: 75%;
                    }
                }
            }
            .--post {
                img {
                    margin: 0 0 .5rem;
                    aspect-ratio: 23/16;
                    width: 100%;
                    object-fit: cover;
                }
                h4 {
                    text-transform: uppercase;
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                }
                span {
                    display: block;
                    text-decoration: underline;
                    font-size: 1.6rem;
                    line-height: 3rem;
                }
                @include breakpoint(medium) {
                    &:hover {
                        h4, span {
                            color: color(base, SecondaryHover);
                        }
                    }
                }
            }
        }
    }
    .category-products-block {
        margin: 4rem 0 0 !important;
        .category-products-block-container {
            .--header {
                button {
                    cursor: pointer;
                    border-top: .1rem solid color(gray, Gray-100);
                    text-transform: uppercase;
                    font-size: 1.6rem;
                    padding: 2rem 0;
                    width: 100%;
                    text-align: left;
                    background-repeat: no-repeat;
                    background-position: right center;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 4.330010) rotate(-270.000000) translate(-5.306354, -4.330010) ' points='2.44757827 -0.169989964 8.16998996 4.3284815 2.44271724 8.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                    font-weight: normal;
                    &.--active {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 5.330010) rotate(-90.000000) translate(-5.306354, -5.330010) ' points='2.44757827 0.830010036 8.16998996 5.3284815 2.44271724 9.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                    }
                }
            }
            .catalog__category-list {
                border-bottom: .1rem solid color(gray, Gray-100);
                ul {
                    margin-bottom: 1rem;
                    li a {
                        margin: 0 0 1.5rem;
                        .product__holder {
                            padding: 2rem 0 0;
                            h3 {
                                text-align: left;
                            }
                            .price__holder {
                                justify-content: left;
                                margin: 1rem 0 0;
                            }
                        }
                        button, > a {
                            @include alternative-button;
                            margin: 1.5rem 0 0;
                            cursor: pointer;
                            padding: 0 2rem;
                            width: 100%;
                        }
                    }
                }
                .--actions {
                    display: flex;
                    justify-content: center;
                    margin: 0 0 2rem;
                    a {
                        @include alternative-button;
                        margin: 1.5rem 0 0;
                        cursor: pointer;
                        padding: 0 2rem;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .video-block-block {
        .--header {
            @include default-title;
        }
        .--content {
            margin: 0 auto;
            @include breakpoint(medium) {
                max-width: 108rem;
            }
            p {
                margin: 0 auto 2.5rem;
                line-height: 3rem;
                font-size: 1.6rem;
                @include breakpoint(medium) {
                    max-width: 66.4rem;
                }
            }
        }
    }
}

.page-builder_body {
    .hero-container ._content {
        &.--offset {
            bottom: 4rem;
        }
        a {
            border: .2rem solid color(base, White);
            min-width: auto;
            padding: 0 1.5rem;
            height: 3.4rem;
            line-height: 3.2rem;
            @include breakpoint(medium) {
                &:hover {
                    background-color: rgba(255, 255, 255, .15);
                }
            }
        }
        span {
            display: none;
        }
    }
}
