.js-off-canvas-overlay.is-visible {
    display: none;
}

.off-canvas.position-left {
    box-shadow: none;
    &:not(.in-canvas-for-medium) {
        background-color: color(base, White);
        @media screen and (max-width: 1000px) {
            transform: translateX(-100%);
            top: 5.6rem;
            @include breakpoint(small only) {
                top: 6.1rem;
            }
        }
    }
    &.is-open {
        width: 100%;
        transform: translateX(0);
        overflow: hidden;
        overflow-y: auto;
        section {
            display: flex;
            flex-direction: column;
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            .modal__header {
                background: color(base, White);
                display: flex;
                padding: 0 1.5rem;
                height: 6.1rem;
                text-transform: none;
                > span.title {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    font-size: 1.4rem;
                    font-weight: 600;
                    color: color(base, Secondary);
                    span {
                        font-size: 1.2rem;
                        color: color(base, Secondary);
                    }
                }
                button {
                    position: initial;
                    margin-right: .5rem;
                    span {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Secondary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18' /%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 2rem;
                        width: 2rem;
                        display: block;
                        background-position: left center;
                    }
                }
            }
            .modal__content {
                height: 100%;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
            }
        }
    }
    .modal__header {
        display: none;
    }
    .search__autocomplete {
        button {
            position: absolute;
            width: 3rem;
            height: 3rem;
            right: 1rem;
            top: .5rem;
            bottom: .5rem;
            cursor: pointer;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='21px' viewBox='0 0 20 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-5' transform='translate(1.000000, 1.000000)' stroke='#{color(base, Secondary)}' stroke-width='1.5'%3E%3Cpath d='M14.77,7.385 C14.77,11.464 11.464,14.77 7.385,14.77 C3.306,14.77 0,11.464 0,7.385 C0,3.306 3.306,0 7.385,0 C11.464,0 14.77,3.306 14.77,7.385 Z' id='Stroke-1'%3E%3C/path%3E%3Cline x1='17.8084' y1='19.2332' x2='11.9594' y2='13.3842' id='Stroke-3'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    nav {
        a, button {
            color: color(base, Secondary);
            font-size: 1.4rem;
            font-weight: normal;
            border-bottom: .1rem solid color(gray, Gray-100);
            text-align: left;
            font-weight: normal;
        }
        > ul > li {
            &.is-drilldown-submenu-parent > a {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Secondary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: right 1rem center;
                background-size: 2rem;
            }
            > ul.nested {
                background: color(base, White);
                height: 99vh;
                li {
                    &.js-drilldown-back {
                        button {
                            padding: 0 0.9375em;
                            width: 100%;
                            line-height: 4.8rem;
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
        .language__select {
            border-bottom: .1rem solid color(gray, Gray-100);
            display: flex;
            flex-wrap: wrap;
            li {
                flex: 1;
                a {
                    display: flex;
                    justify-content: center;
                    border: none;
                    padding: 1rem;
                    &.--active {
                        background-color: color(gray, Gray-100);
                        pointer-events: none;
                    }
                }
                &:not(:last-child) a {
                    border-right: .1rem solid color(gray, Gray-100);
                }
            }
        }
    }
}
