.related__items, .sliders-block {
    max-width: 192rem;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    #product-alternatives {
        position: absolute;
        left: 0;
        top: -80px;
    }
    &.--top {
        > section {
            margin: 0;
            .catalog__category-list {
                margin: 0;
            }

            .--header {
                margin-top: 0;
            }
        }
    }
    > section {
        margin: 0;

        .catalog__product_media-slider,
        .catalog__product_media-info-slider {
            display: flex;
            align-items: center;

            .swiper-container {
                .swiper-wrapper {
                    justify-content: flex-start;
                }
            }
            &.swiper-slide-active {
                position: fixed;
                height: 100%;
                z-index: 10;
                display: flex;
                align-items: center;
                .swiper-container {
                    width: 100%;
                }
            }
        }
        .product-media-slider {
            .swiper-container {
                .swiper-wrapper {
                    justify-content: flex-start;
                }
            }
        }
        .--header {
            margin-top: 5rem;
            @include default-title;
        }
        .swiper__pagination {
            position: absolute;
            top: 1rem;
            right: 5rem;
        }
        .swiper-container {
            width: 100%;
            .swiper-wrapper {
                @include breakpoint(medium){
                    justify-content: center;
                }
            }
            .swiper-slide {
                width: 75%;
                &:first-of-type {
                    margin-left: 1rem;
                }
                @include breakpoint(medium) {
                    width: 17%;
                    &:first-of-type {
                        margin-left: 2.5rem;
                    }
                }
            }
        }
        .--long {
            .swiper-wrapper {
                @include breakpoint(medium){
                    justify-content: flex-start;
                }
            }
        }
    }
    ul li .out-of-stock--label {
        margin-left: 0;
    }
}

.last__viewed {
    .--header {
        margin: 0;
    }
}
