.catalog__category-list {
    position: relative;

    ._loading {
        background: rgba(255, 255, 255, .7);
    }

    ul li {
        position: relative;

        .out-of-stock--label {
            background: rgb(244, 245, 250);
            top: 0;
            left: 0;
            position: absolute;
            margin-left: 1rem;
            padding: .8rem 2.5rem;
            border: solid 1px color(base, Secondary);
            color: color(base, Secondary);
            font-weight: 700;
        }

        .discount {
            background: color(base, Secondary);
            top: 0;
            right: 0;
            position: absolute;
            margin-right: 1rem;
            padding: .8rem 2.5rem;
            border: solid 1px darken(#F4F5FA, 10);
            color: color(base, White);
            font-weight: 700;
            font-size: 1.4rem;
        }

        a, > span {
            display: block;

            .image__holder {
                position: relative;
                background-color: #F4F5FA;
                img {
                    aspect-ratio: 7/10;
                    width: 100%;
                    object-fit: contain;
                }

                .add__to-cart {
                    &:not(.--visible) {
                        display: none;
                    }
                    .add-to-cart__container {
                        position: absolute;
                        bottom: 1.5rem;
                        left: 1.5rem;
                        right: 1.5rem;

                        button, a {
                            width: 100%;
                            cursor: pointer;
                            display: block;
                            background-color: color(base, Orange);
                            color: color(base, White);
                            font-size: 1.4rem;
                            line-height: 4.8rem;
                            text-transform: uppercase;
                            text-align: center;
                            background-position: right 1.5rem center;
                            background-repeat: no-repeat;
                            font-weight: 500;
                            @include breakpoint(medium) {
                                &:not(:disabled):hover {
                                    background: color(base, OrangeHover);
                                }
                            }

                            &.--loading-cart {
                                background-image: url("data:image/svg+xml,%0A%3C!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL --%3E%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='8.042%25' y1='0%25' x2='65.682%25' y2='23.865%25' id='a'%3E%3Cstop stop-color='#{color(base, White)}' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='#{color(base, White)}' stop-opacity='.631' offset='63.146%25'/%3E%3Cstop stop-color='#{color(base, White)}' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(%23a)' stroke-width='2'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/path%3E%3Ccircle fill='#{color(base, White)}' cx='36' cy='18' r='1'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                                background-size: 2rem;
                            }

                            &.--added-cart {
                                background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='20px' viewBox='0 0 26 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(alert, Success)}' stroke-width='5' points='2 9.4929108 8.5070892 16 23.3947226 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                                background-size: 1.6rem;
                            }

                            &:disabled {
                                cursor: not-allowed;
                                background-color: color(gray, Gray-300);
                            }
                        }

                        > span {
                            position: absolute;
                            left: 0;
                            right: 0;
                        }

                        .add-to-cart__holder {
                            display: flex;
                            justify-content: center;
                            gap: .5rem;

                            .--options {
                                flex: 1;

                                select {
                                    width: 100%;
                                    height: 4.6rem;
                                    line-height: 4.6rem;
                                }
                            }

                            > select {
                                width: auto;
                                height: 4.6rem;
                                line-height: 4.6rem;
                            }

                            button {
                                flex: 0 0 4.6rem;
                                width: 4.6rem;
                                height: 4.6rem;
                                background-image: url("data:image/svg+xml,%0A%3Csvg width='21' height='21' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='#{color(base, White)}' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpath d='M1 19.884h19.111V4.943H1z'/%3E%3Cpath d='M14.667 8.849V5.056a4.056 4.056 0 0 0-8.113 0v3.793'/%3E%3C/g%3E%3C/svg%3E");
                                background-position: center;

                                &.--loading {
                                    background-image: url("data:image/svg+xml,%0A%3C!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL --%3E%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='8.042%25' y1='0%25' x2='65.682%25' y2='23.865%25' id='a'%3E%3Cstop stop-color='#{color(base, White)}' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='#{color(base, White)}' stop-opacity='.631' offset='63.146%25'/%3E%3Cstop stop-color='#{color(base, White)}' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(%23a)' stroke-width='2'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/path%3E%3Ccircle fill='#{color(base, White)}' cx='36' cy='18' r='1'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                                    background-size: 2rem;
                                }

                                &.--added {
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='20px' viewBox='0 0 26 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, White)}' stroke-width='5' points='2 9.4929108 8.5070892 16 23.3947226 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                                    background-size: 1.6rem;
                                }

                                &:not(.--loading):not(.--added):hover {
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='21' height='21' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='#{color(base, White)}' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpath d='M1 19.884h19.111V4.943H1z'/%3E%3Cpath d='M14.667 8.849V5.056a4.056 4.056 0 0 0-8.113 0v3.793'/%3E%3C/g%3E%3C/svg%3E");
                                }
                            }
                        }
                    }
                }
            }

            .product__holder {
                display: block;
                padding: 1rem 2rem 2rem;

                h3 {
                    display: block;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    line-height: 2rem;
                    font-weight: 500;
                    color: color(base, Secondary);
                    @include breakpoint(medium) {
                        font-size: 1.6rem;
                    }
                }

                .price__holder {
                    display: flex;
                    margin: 1rem 0 0;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;

                    > span {
                        display: block;
                        font-size: 1.5rem;
                        line-height: 1;
                        color: color(base, Secondary);

                        &.--special {
                            color: color(base, Orange);
                        }

                        &.--old {
                            background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%);
                        }
                    }
                }

                .product__swatches {
                    display: flex;
                    justify-content: center;
                    gap: 1rem;
                    margin: 1.5rem 0 0;

                    span {
                        display: block;
                        width: 1.2rem;
                        height: 1.2rem;
                        background: Black;
                        cursor: pointer;

                        &:nth-child(2) {
                            background: rgb(199, 14, 14);
                        }

                        &:nth-child(3) {
                            background: rgb(86, 184, 30);
                        }
                    }
                }
            }

            @include breakpoint(medium) {
                &:hover {
                    .add__to-cart {
                        display: block;
                    }
                }
            }
        }
    }
}
