.contact-form {
    .messages {
        margin: 0 0 2rem;
    }
    h1 {
        @include default-title-element;
        text-align: left;
    }
    .contact__block {
        background: color(gray, Gray-150);
        padding: 2rem;
        @include breakpoint(small only) {
            margin: 0 0 2rem;
        }
        h2 {
            margin: 0 0 1rem;
            font-size: 1.8rem;
        }
        p {
            margin: 0 0 1.5rem;
        }
        a {
            text-decoration: underline;
            &:hover {
                color: color(base, SecondaryHover);
            }
        }
        ul {
            margin: 0 0 1.5rem 2rem;
            list-style: outside disc;
            li {
                font-size: 1.4rem;
                line-height: 2.1rem;
            }
        }
    }
}
