footer {
    margin: 8rem 0 0;
    .footer-container {
        background: color(gray, Gray-150);
        padding: 6rem 2rem 4rem;
        @include breakpoint(medium) {
            padding: 6rem 0 5rem;
        }
        .footer__logo {
            display: block;
            margin: 0 0 4rem;
        }
        h3 {
            margin: 4rem 0 2rem;
            @include breakpoint(medium) {
                margin: 0 0 4rem;
            }
        }
        h3, h3 > a {
            font-size: 1.2rem;
            text-transform: uppercase;
            color: color(gray, Gray-400);
            font-weight: 500;
            @include breakpoint(medium) {
                font-size: 1.4rem;
            }
        }
        ul {
            li a {
                font-size: 1.2rem;
                text-transform: uppercase;
                color: color(gray, Gray-500);
                line-height: 3rem;
                @include breakpoint(medium) {
                    font-size: 1.4rem;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .--divider {
            display: block;
            height: .1rem;
            background: color(gray, Gray-100);
            width: 100%;
            margin: 4rem 0;
        }
        .review__widget {
            font-size: 1.8rem;
            display: flex;
        }
        .newsletter__container {
            .newsletter__container-form {
                display: flex;
                input {
                    height: 3.6rem;
                    line-height: 3.6rem;
                    font-size: 1.2rem;
                }
                @include breakpoint(small only) {
                    flex-wrap: wrap;
                    > * {
                        flex: 0 0 100%;
                    }
                }
            }
        }
        .--socials {
            display: flex;
            gap: 1rem;
            a {
                @include transition;
                display: flex;
                width: 3.8rem;
                height: 3.8rem;
                align-items: center;
                justify-content: center;
                border: .28rem solid color(gray, Gray-300);
                border-radius: 50%;
                @include breakpoint(medium) {
                    &:hover {
                        border-color: color(gray, Gray-400);
                    }
                }
            }
        }
    }
    .absolute__footer {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        @include breakpoint(small only) {
            flex-wrap: wrap;
            justify-content: center;
        }
        > span, p {
            display: flex;
            margin-left: auto;
            gap: 1rem;
            flex-wrap: wrap;
            @include breakpoint(small only) {
                margin: 1.5rem 0 0;
            }
            > span, > a {
                display: block;
                color: color(gray, Gray-500);
                text-transform: uppercase;
                line-height: 3rem;
                font-size: 1.2rem;
                @include breakpoint(small only) {
                    font-size: .9rem;
                }
            }
            > a {
                &::after {
                    content: '|';
                    margin-left: 1rem;
                }
                @include breakpoint(small only) {
                    &:last-of-type {
                        &::after {
                            display: none;
                        }
                    }
                }
                @include breakpoint(medium) {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            @include breakpoint(small only) {
                justify-content: center;
                > span {
                    flex-basis: 100%;
                    line-height: 1.8rem;
                    text-align: center;
                    padding: 0 20%;
                }
            }
        }
    }
}
