.search__autocomplete {
    position: relative;
    .search__autocomplete_dropdown {
        position: absolute;
        left: -40%;
        right: -40%;
        top: 2.8rem;
        background: color(base, White);
        ul {
            li {
                a {
                    display: flex;
                    padding: .5rem;
                    border-bottom: .1rem solid color(gray, Gray-100);
                    align-items: center;
                    span {
                        display: block;
                    }
                }
            }
        }
    }
}
