/* Override default */
$grid__max-width: 136.8rem;
$grid__gutter: 2rem;
$grid__max-padding: 2rem;

$grid__breakpoints: (
    '': 0,
    small: 576,
    medium: 768,
    between-medium-small: 941,
    large: 1025,
    xlarge: 1200,
    xxlarge: 1440
) !default;

$color-palette: (
    base: (
        BodyBackground: #FFF,
        White: #FFF,
        White20: #FFFFFF20,
        White80: #FFFFFF80,
        Black: #000,
        DefaultFontColor: #000,
        inputColor: #000,
        Primary: #FF003D,
        PrimaryHover: darken(rgb(255, 0, 61), 5%),
        PrimaryLight: lighten(rgb(255, 0, 61, .75), 10%),
        RedSemiTransparent: rgba(255, 0, 61, .7),
        RedSemiTransparentHover: rgba(255, 0, 61, .9),
        Secondary: #001738,
        SecondaryHover: darken(rgb(0, 23, 56), 5%),
        SecondaryBorder: rgba(0, 23, 56, .2),
        MistralSand: #E5E5DA,
        MistralLightBlue: #C7EFEB,
        MistralLightBlueHover: darken(rgb(199, 239, 235), 5%),
        CallToAction: rgb(95, 216, 103),
        CallToActionHover: darken(rgb(95, 216, 103), 10%),
        Orange: rgb(255, 132, 0),
        OrangeHover: darken(rgb(255, 132, 0), 10%),
        DarkGrey: #171717
    ),
    gray: (
        Gray-100: #E9EAF2,
        Gray-150: #F7F8FF,
        Gray-200: #B5B9C9,
        Gray-300: #9A9EB0,
        Gray-400: #6b6b6b,
        Gray-500: #444,
        Gray-800: #3C3A3A,
        Gray-900: #2F2F2F
    ),
    alert: (
        Error: #D8000C,
        Warning: #9F6000,
        Success: #270,
        Neutral: #059
    )
) !default;

$default-font: 'Roboto', sans-serif;
$secondary-font: 'Roboto', sans-serif;
$grotesk-font: 'Archivo', sans-serif;
$stolzl-font: 'Questrial', sans-serif;
