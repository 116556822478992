.catalog-category-page {
    .hero-container picture img {
        height: 40vh;
    }
    .banner__text {
        margin: 2rem 0 0;
        h2, h3, h4 {
            font-size: 2.4rem;
            text-transform: uppercase;
            margin: 0 0 1rem;
        }
        p {
            font-size: 1.5rem;
            line-height: 2.7rem;
            &:not(:last-of-type) {
                margin: 0 0 2.5rem;
            }
            a {
                font-size: 1.5rem;
                line-height: 2.7rem;
                text-decoration: underline;
            }
        }
    }
    @include breakpoint(small only) {
        .breadcrumbs {
            display: none;
        }
    }
    h1 {
        text-transform: uppercase;
        color: color(base, Secondary);
        font-size: 2rem;
        text-align: center;
        margin: 2rem 0;
        @include breakpoint(medium) {
            font-size: 3.2rem;
            text-align: left;
            margin: 0;
        }
    }
    .catalog__toolbar {
        align-items: center;
        margin: 0 0 2rem;
        @include breakpoint(medium) {
            display: flex;
        }
        .toggle__filter {
            width: 100%;
            margin: 0 0 1rem;
            button {
                color: color(base, Secondary);
                border: .2rem solid color(gray, Gray-100);
                font-size: 1.4rem;
                line-height: 4.8rem;
                font-weight: 500;
                text-transform: uppercase;
                width: 100%;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 4.330010) rotate(-270.000000) translate(-5.306354, -4.330010) ' points='2.44757827 -0.169989964 8.16998996 4.3284815 2.44271724 8.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: initial;
                background-position: right 1.5rem center;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: .3rem;
                span {
                    font-size: 1.2rem;
                    color: color(base, Secondary);
                    padding: 0 0 0.2rem;
                }
            }
        }
        > span {
            font-size: 1.2rem;
            color: color(base, Secondary);
            line-height: normal;
        }
        .catalog-category__sorter {
            display: flex;
            align-items: center;
            margin-left: auto;
            gap: 2rem;
            label {
                display: block;
                font-size: 1.2rem;
                color: color(base, Secondary);
                text-transform: uppercase;
                font-weight: 500;
                @include breakpoint(small only) {
                    display: none;
                }
            }
            select {
                flex: 1;
                line-height: 3.6rem;
                height: 3.6rem;
                font-size: 1.2rem;
                option {
                    line-height: 2rem;
                }
                @include breakpoint(medium) {
                    min-width: 20rem;
                }
                @include breakpoint(small only) {
                    border-width: .2rem;
                    line-height: 4.8rem;
                    height: 4.8rem;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 0 3.5rem;
                    font-size: 1.4rem;
                    font-weight: 500;
                }
            }
        }
    }
    .category__bottom {
        margin: 3rem 0 0;
    }
    .category__description {
        margin: 8rem 0 0;
        .--content {
            h2, h3, h4 {
                font-size: 2.4rem;
                text-transform: uppercase;
                margin: 0 0 1rem;
            }
            p {
                font-size: 1.5rem;
                line-height: 2.7rem;
                &:not(:last-of-type) {
                    margin: 0 0 2.5rem;
                }
                a {
                    font-size: 1.5rem;
                    line-height: 2.7rem;
                    text-decoration: underline;
                }
            }
        }
        .--actions {
            margin: 3rem 0 0;
            button {
                cursor: pointer;
                font-size: 1.5rem;
                line-height: 2.7rem;
                text-decoration: underline;
            }
        }
    }
}
