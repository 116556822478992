.store__locator {
    ._content {
        h1 {
            @include head-font;
            font-size: 2.6rem;
            font-weight: 600;
            text-transform: uppercase;
            line-height: normal;
            margin: 0 0 2rem;
        }
        p {
            margin: 0 0 2rem;
        }
    }
    .vue-map-container {
        width: 100%;
        height: 500px;
        .gm-style-iw {
            h4 {
                font-weight: 600;
                font-size: 1.8rem;
                margin: 0 0 .5rem;
            }
            a {
                display: block;
                @include breakpoint(medium) {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
