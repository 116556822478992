header {
    .sticky__holder {
        height: .1rem;
    }
    .sticky {
        background: color(base, White);
        border-bottom: .1rem solid color(gray, Gray-100);
        padding: 1.5rem;
        @include breakpoint(medium) {
            padding: 1rem 0 .5rem;
        }
        > .grid-container {
            display: flex;
            align-items: center;
            .mobile__menu {
                flex: 0 0 7rem;
                justify-content: left;
                align-self: flex-end;
                display: none;
                @include breakpoint(medium) {
                    flex: 0 0 5rem;
                    margin-bottom: 1rem;
                }
                @media screen and (max-width: 1000px) {
                    display: flex;
                }
                .menu__toggle {
                    width: 2.2rem;
                    height: 1.7rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    > span {
                        height: .3rem;
                        width: 100%;
                        background-color: color(base, Secondary);
                        transition: all 100ms ease-in-out;
                        &.--close {
                            &:nth-of-type(1) {
                                transition: all 100ms ease-in-out;
                                transform: rotate(45deg);
                                transform-origin: top left;
                                width: 2.1rem;
                            }
                            &:nth-of-type(2) {
                                transition: all 100ms ease-in-out;
                                transform-origin: center;
                                width: 0;
                            }
                            &:nth-of-type(3) {
                                transition: all 100ms ease-in-out;
                                transform: rotate(-45deg);
                                transform-origin: bottom left;
                                width: 2.1rem;
                            }
                        }
                    }
                }
            }
            .--logo {
                display: flex;
                flex: 1;
                justify-content: center;
                @include breakpoint(medium) {
                    flex: 0 0 11.7rem;
                }
            }
            .header__nav {
                flex: 1;
                margin: 0 4rem;
                padding: 1.5rem 0 0;
                position: relative;
                @media screen and (max-width: 1000px) {
                    display: none;
                }
                > ul {
                    display: flex;
                    align-items: center;
                    gap: clamp(.5rem, 4vw, 3rem);
                    @media screen and (max-width: 1265px) {
                        gap: 2rem;
                    }
                    > li {
                        &.orange {
                           > a {
                                color: color(base, Orange);

                                &:after {
                                    background: color(base, Orange);
                                }
                            }
                        }
                        > a, > span {

                            @include transition;
                            cursor: pointer;
                            display: block;
                            font-size: 1.4rem;
                            text-transform: uppercase;
                            color: color(base, Secondary);
                            line-height: 3rem;
                            position: relative;
                            font-weight: 500;
                            @media screen and (max-width: 1265px) {
                                font-size: 1.4rem;
                                @media screen and (max-width: 1135px) {
                                    font-size: 1.2rem;
                                }
                            }
                            &::after {
                                @include transition;
                                content: '';
                                height: .2rem;
                                width: 0;
                                background: color(base, Secondary);
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 100%;
                            }
                        }
                        .--dropdown {
                            display: none;
                            background: color(base, BodyBackground);
                            position: absolute;
                            top: 4.7rem;
                            left: -4rem;
                            right: -4rem;
                            padding: 4rem;
                            ul {
                                flex: 1;
                                li {
                                    font-size: 1.4rem;
                                    line-height: 3.4rem;
                                    text-transform: uppercase;
                                    font-weight: 500;
                                    a {
                                        font-weight: normal;
                                        font-size: 1.4rem;
                                        line-height: 3.4rem;
                                        text-transform: uppercase;
                                        @include breakpoint(medium) {
                                            &:hover {
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        > li {
                            > a, > span {
                                color: color(gray, Gray-200);
                            }
                            &:hover {
                                > a, > span {
                                    color: color(base, Secondary);
                                    &::after {
                                        width: 100%;
                                    }
                                }
                                .--dropdown {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
            .header--right {
                display: flex;
                margin-left: auto;
                gap: 3rem;
                @media screen and (max-width: 1265px) {
                    gap: 2rem;
                }
                .search-form__holder {
                    @include search-input;
                    width: 24rem;
                    @media screen and (max-width: 1265px) {
                        width: 18rem;
                    }
                }
                .right__menu {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    @include breakpoint(medium) {
                        gap: 3rem;
                        @media screen and (max-width: 1265px) {
                            gap: 2rem;
                        }
                    }
                    > section {
                        width: auto;
                    }
                    > a, > section a, button {
                        display: block;
                        width: 3rem;
                        height: 3rem;
                        background-repeat: no-repeat;
                        background-position: bottom;
                        @include breakpoint(medium) {
                            background-position: center;
                        }
                        &._search {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='21px' viewBox='0 0 20 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-5' transform='translate(1.000000, 1.000000)' stroke='#{color(base, Secondary)}' stroke-width='1.5'%3E%3Cpath d='M14.77,7.385 C14.77,11.464 11.464,14.77 7.385,14.77 C3.306,14.77 0,11.464 0,7.385 C0,3.306 3.306,0 7.385,0 C11.464,0 14.77,3.306 14.77,7.385 Z' id='Stroke-1'%3E%3C/path%3E%3Cline x1='17.8084' y1='19.2332' x2='11.9594' y2='13.3842' id='Stroke-3'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        }
                        &._customer {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='21px' height='22px' viewBox='0 0 21 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-5' transform='translate(1.000000, 1.000000)' stroke='#{color(base, Secondary)}' stroke-width='1.5'%3E%3Cpath d='M17.581,11.119 L1.53,11.119 C0.685,11.119 0,11.804 0,12.649 L0,18.044 C0,18.889 0.685,19.574 1.53,19.574 L17.581,19.574 C18.426,19.574 19.111,18.889 19.111,18.044 L19.111,12.649 C19.111,11.804 18.426,11.119 17.581,11.119 Z' id='Stroke-1'%3E%3C/path%3E%3Cpath d='M14.0726,4.517 C14.0726,7.012 12.0506,9.034 9.5556,9.034 C7.0606,9.034 5.0386,7.012 5.0386,4.517 C5.0386,2.022 7.0606,0 9.5556,0 C12.0506,0 14.0726,2.022 14.0726,4.517 Z' id='Stroke-3'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        }
                        &._cart {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='21px' height='21px' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-4' transform='translate(1.000000, 1.000000)' stroke='#{color(base, Secondary)}' stroke-width='1.5'%3E%3Cpolygon id='Stroke-1' points='0 18.884 19.111 18.884 19.111 3.943 0 3.943'%3E%3C/polygon%3E%3Cpath d='M13.6672,7.849 L13.6672,4.056 C13.6672,1.816 11.8512,0 9.6112,0 C7.3702,0 5.5542,1.816 5.5542,4.056 L5.5542,7.849' id='Stroke-3'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                            position: relative;
                            > span {
                                background: color(base, Orange);
                                display: flex;
                                font-size: .9rem;
                                color: color(base, White);
                                align-items: center;
                                justify-content: center;
                                border-radius: 50%;
                                width: 1.5rem;
                                height: 1.5rem;
                                position: absolute;
                                top: 0;
                                right: -.2rem;
                                font-weight: 500;
                            }
                        }
                    }
                    ._language {
                        position: relative;
                        > span {
                            cursor: pointer;
                        }
                        ul {
                            display: none;
                            position: absolute;
                            top: 100%;
                            background: color(base, BodyBackground);
                            left: -1rem;
                            right: -1rem;
                            padding: 1rem 0 0;
                            li a {
                                display: block;
                                margin: 0 0 1rem;
                                text-align: center;
                                img {
                                    margin: 0 auto;
                                }
                            }
                        }
                        @include breakpoint(medium) {
                            &:hover > ul {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
