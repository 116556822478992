.checkout-success-page {
    padding: 3rem 0 0;
    .breadcrumbs {
        display: none;
    }
    h1 {
        margin: 0 0 2rem;
        font-size: 2.2rem;
        text-transform: uppercase;
    }
    .order__information {
        p {
            margin: 2rem 0 0;
            strong {
                font-weight: normal;
            }
        }
        .order__address {
            background: color(gray, Gray-100);
            padding: 1rem;
            margin: 2rem 0;
            h4 {
                font-size: 1.4rem;
                margin: 0 0 1rem;
                text-transform: uppercase;
            }
            ul {
                font-size: 1.4rem;
            }
        }
    }
}
