.home {
    .block__slider {
        @include block-spacing;
        @include breakpoint(small only) {
            padding: 0 0 0 2rem;
        }
        .header {
            position: relative;
            h2 {
                text-transform: uppercase;
                text-align: center;
                padding: 0 2rem 0 0;
                margin: 0 auto 4rem;
                @include breakpoint(medium) {
                    max-width: 30%;
                }
            }
            ._pagination {
                position: absolute;
                right: 2rem;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                > div {
                    @include transition;
                    cursor: pointer;
                    width: 4.8rem;
                    height: 4.8rem;
                    background-color: color(base, Primary);
                    margin-left: 1rem;
                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: center;
                    &.swiper-button-disabled {
                        background-color: color(gray, Gray-100);
                        cursor: not-allowed;
                        opacity: .6;
                    }
                    &._next {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='17px' viewBox='0 0 11 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-10' transform='translate(-90.000000, -17.000000)' stroke='%230A233D' stroke-width='2.363'%3E%3Cpolyline id='Stroke-3' points='91.8932 18.5503 98.9832 25.8673 91.8932 32.7313'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    }
                    &._prev {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='10px' height='17px' viewBox='0 0 10 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Group-10' transform='translate(-17.000000, -15.000000)' stroke='%230A233D' stroke-width='2.363'%3E%3Cpolyline id='Stroke-8' points='25.703 30.7255 18.613 23.4085 25.703 16.5445'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    }
                    &:not(.swiper-button-disabled):hover {
                        background-color: color(base, PrimaryHover);
                    }
                }
            }
        }
        .swiper-container {
            padding-bottom: .5rem;
            @include breakpoint(medium) {
                .swiper-wrapper {
                    margin: 0 -1rem;
                }
            }
            .swiper-slide {
                width: 75%;
                @include breakpoint(medium) {
                    @media screen and (max-width: 76.8rem) {
                        width: 30%;
                    }
                    width: 18.75%;
                    &:first-child {
                        width: 25%;
                    }
                }
                ._slide {
                    @include breakpoint(medium) {
                        margin: 0 1rem;
                    }
                    position: relative;
                    border-radius: 1.5rem;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 22rem;
                        object-fit: cover;
                    }
                    span {
                        @include head-font;
                        position: absolute;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        color: color(base, Primary);
                        text-transform: uppercase;
                        line-height: normal;
                        font-size: 2rem;
                    }
                    &._first {
                        background: color(base, Primary);
                        @include head-font;
                        height: 22rem;
                        padding: 0 2.5rem;
                        font-size: clamp(1.6rem, 2vw, 2.4rem);
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    .home__heritage {
        @include block-spacing;
        background: color(gray, Gray-100);
        padding: 3rem 2rem;
        @include breakpoint(medium) {
            padding: 5rem 2rem;
        }
        h2 {
            text-align: center;
            text-transform: uppercase;
            margin: 0 auto 3rem;
            @include breakpoint(medium) {
                max-width: 30%;
            }
        }
        p {
            @include breakpoint(medium) {
                max-width: 70%;
                margin: 0 auto;
                font-size: 1.6rem;
                line-height: 2rem;
            }
        }
    }
    .home__block {
        @include block-spacing;
        @include breakpoint(medium) {
            .grid-container {
                display: flex;
            }
        }
        .grid-container {
            justify-content: space-between;
            flex-wrap: wrap;
            picture {
                margin: 0 auto;
            }
            > span, ._content > span {
                display: block;
                text-transform: uppercase;
                font-weight: normal;
                font-size: 1.2rem;
                margin: 0 0 .5rem;
                @include breakpoint(small only) {
                    padding: 0 2rem;
                }
            }
            @include breakpoint(medium) {
                > h2, > span {
                    display: none;
                }
            }
            h2 {
                order: 0;
                text-transform: uppercase;
                margin: 0 0 3rem;
                @include breakpoint(small only) {
                    padding: 0 2rem;
                }
            }
            img {
                flex: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include breakpoint(medium) {
                    max-width: 65rem;
                    max-height: 52rem;
                    aspect-ratio: 5/4;
                }
            }
            .image__content {
                position: relative;
                width: 100%;
                @include breakpoint(medium) {
                    order: 1;
                    width: 50%;
                }
                > div {
                    padding: 2rem;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    display: flex;
                    align-items: flex-end;
                    > div {
                        text-align: center;
                        span {
                            @include head-font;
                            display: block;
                            color: color(base, Primary);
                            text-transform: uppercase;
                            font-size: 5rem;
                            line-height: 5.4rem;
                            text-align: left;
                            margin: 0 0 3rem;
                            @include shadow(text);
                            @include breakpoint(medium) {
                                font-size: 7rem;
                                line-height: 7rem;
                                margin: 0 0 20%;
                            }
                        }
                        a {
                            @include shadow();
                        }
                    }
                }
            }
            ._content {
                flex: 1;
                padding: 0 5rem;
                @include breakpoint(small only) {
                    padding: 2rem;
                    > h2, > span {
                        display: none;
                    }
                    > div {
                        padding: 0 2rem;
                    }
                }
                @include breakpoint(medium) {
                    margin: 3rem 0 0;
                    p {
                        font-size: 1.6rem;
                        line-height: 2rem;
                    }
                }
                h3 {
                    color: color(gray, Gray-300);
                    text-transform: uppercase;
                    margin: 3rem 0;
                    @include breakpoint(medium) {
                        margin: 0 0 3rem;
                    }
                }
                a._button {
                    margin: 3rem 0 0;
                }
            }
        }
        &._alternative {
            background: color(gray, Gray-100);
            padding: 3rem 0;
            ._content {
                padding: 0;
                @include breakpoint(medium) {
                    margin-left: -1rem;
                    padding: 0 10rem 0 2rem;
                    width: 50%;
                }
            }
            h2 {
                text-align: center;
                display: block;
                width: 100%;
            }
            p {
                font-weight: 100;
            }
        }
        &._right {
            @include breakpoint(medium) {
                &:not(._alternative) {
                    picture, img {
                        order: 2;
                    }
                }
                &._alternative {
                    .grid-container ._content {
                        order: 2;
                        padding: 0 0 0 10rem;
                    }
                }
            }
        }
    }
    .home__video {
        @include block-spacing;
        position: relative;
        @include breakpoint(small only) {
            min-height: 54rem;
        }
        img {
            width: 100%;
            object-fit: cover;
            @include breakpoint(small only) {
                min-height: 70rem;
            }
            @include breakpoint(medium) {
                max-height: 70rem;
            }
        }
        ._content {
            position: absolute;
            padding: 2rem;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            align-items: center;
            .grid-container {
                @include breakpoint(medium) {
                    display: flex;
                }
                h2 {
                    color: color(base, Primary);
                    text-transform: uppercase;
                    @include shadow(text);
                    margin: 0 0 2rem;
                    font-size: 4rem;
                    line-height: normal;
                }
                p {
                    color: color(base, Primary);
                    @include shadow(text);
                    margin: 0 0 3rem;
                    @include breakpoint(medium) {
                        font-size: 1.6rem;
                        line-height: 2rem;
                        max-width: 75%;
                    }
                }
                iframe {
                    border-radius: 1rem;
                    @include shadow();
                }
                a {
                    margin: 3rem 0 0;
                    @include shadow();
                }
            }
        }
    }
    .home__sale {
        @include block-spacing;
        background: color(base, Secondary);
        img {
            width: 100%;
        }
        ._content {
            padding: 2rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: 100%;
            h2 {
                color: color(base, Primary);
                text-transform: uppercase;
                font-size: clamp(2rem, 2vw, 3.8rem);
                line-height: normal;
                word-break: break-all;
            }
            a {
                margin: 30px 0 0;
                padding: .6rem 4.5rem;
            }
        }
    }
}

.home_body footer {
    margin: 0;
}
