@use "sass:math";

.page-builder__wrapper {
    .page-builder__content-block {

        .home__html {
            h1 {
                font-size: 3.6rem;
                margin: 0 0 1.5rem;
            }

            h2, h3, h4 {
                margin: 0 0 1.5rem;
            }

            em {
                font-size: 1.8rem;
                line-height: 3rem;
                font-style: italic;
            }

            p, strong {
                font-size: 1.8rem;
                line-height: 3rem;
            }

            p:not(:last-child) {
                margin: 0 0 1.5rem;
            }

            ul, ol {
                font-size: 1.8rem;
                line-height: 3rem;
                list-style: disc inside;
                margin: 0 0 1.5rem;

                li {
                    font-size: 1.8rem;
                    line-height: 3rem;
                }
            }

            a {
                font-size: 1.8rem;
                line-height: 3rem;
                text-decoration: underline;
            }
        }

        > div {
            overflow: hidden;
            margin: 4rem 0 0;
            @include breakpoint(medium) {
                margin: 8rem 0 0;
            }
        }
        &:first-child > div {
            margin: 0;
        }
        &:last-child {
            margin-bottom: 4rem;
        }
        .four-column-banners {
            .--header {
                @include default-title;
            }
            @include breakpoint(small only) {
                .cell:not(:last-child) a {
                    margin: 0 0 1rem;
                }
            }
            a {
                display: block;
                position: relative;
                img {
                    @include transition;
                    width: 100%;
                }
                span {
                    @include text-shadow;
                    font-family: $stolzl-font;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    font-size: clamp(4.4rem, 6vw, 4.8rem);
                    text-transform: uppercase;
                    text-align: center;
                    color: color(base, White);
                    line-height: 1;
                    word-break: break-word;
                    padding: 4rem 2rem;
                }
                @include breakpoint(medium) {
                    &:hover {
                        img {
                            filter: brightness(80%);
                        }
                    }
                }
            }
        }

        .two-column-banners {
            .--header {
                @include default-title;
            }
            .two-column-banners-container {
                max-width: 192rem;
                margin: 0 auto;
                display: flex;
                @include breakpoint(small only) {
                    flex-flow: column;
                }
                a, > span {
                    flex: 1;
                    display: block;
                    position: relative;
                    img {
                        @include transition;
                    }
                    span {
                        display: block;
                        position: absolute;
                        text-align: center;
                        bottom: 10%;
                        left: 50%;
                        transform: translateX(-50%);
                        padding: .5rem 3.5rem;
                        text-transform: uppercase;
                        font-family: $stolzl-font;
                        background: color(base, MistralLightBlue);
                        font-size: 2.2rem;
                        line-height: normal;
                        min-width: 23.5rem;
                        @include breakpoint(medium) {
                            font-size: 4.8rem;
                            bottom: 5%;
                        }
                    }
                    @include breakpoint(medium) {
                        &:hover {
                            img {
                                filter: brightness(80%);
                            }
                            span {
                                background: color(base, MistralLightBlueHover);
                            }
                        }
                    }
                }
            }
        }

        .team-riders {
            .--header {
                @include default-title;
            }
            .team-riders-container {
                max-width: 192rem;
                margin: 0 auto;
                position: relative;
                .swiper__pagination {
                    position: absolute;
                    top: 1rem;
                    right: 5rem;
                }
                .swiper-container {
                    .swiper-slide {
                        width: 88%;
                        &:first-child {
                            margin-left: 1rem;
                            @include breakpoint(medium) {
                                margin-left: 2.5rem;
                            }
                        }
                        @include breakpoint(medium) {
                            width: 23%;
                        }
                        > a, > div {
                            display: block;
                            > picture img {
                                aspect-ratio: 7/10;
                                object-fit: cover;
                                width: 100%;
                                height: auto;
                            }
                            .--title {
                                @include letter-spacing;
                                display: block;
                                text-align: center;
                                text-transform: uppercase;
                                font-size: 1.5rem;
                                line-height: 1.8rem;
                                margin: 2.5rem 0 0;
                                color: color(base, Secondary);
                                font-weight: 500;
                                @include breakpoint(medium) {
                                    font-size: 1.6rem;
                                    margin: 3rem 0 0;
                                }
                            }
                            .--country {
                                @include letter-spacing;
                                color: color(base, Secondary);
                                display: flex;
                                margin: .5rem 0 0;
                                justify-content: center;
                                align-items: center;
                                gap: .8rem;
                                font-size: 1.5rem;
                                text-transform: uppercase;
                                line-height: 1.5;
                                img {
                                    aspect-ratio: 16/11;
                                }
                            }
                        }
                    }
                }
            }
        }

        .featured-block {
            border-bottom: 3rem solid color(base, MistralLightBlue);
            .--header {
                @include default-title;
            }
            .featured-block-container {
                max-width: 192rem;
                margin: 0 auto;
                position: relative;
                img {
                    width: 100%;
                    @include breakpoint(small only) {
                        min-height: 37rem;
                        object-fit: cover;
                    }
                }
                .--content {
                    position: absolute;
                    top: 2rem;
                    left: 1rem;
                    right: 1rem;
                    bottom: 1rem;
                    display: flex;
                    flex-flow: column;
                    @include breakpoint(medium) {
                        top: 10rem;
                        left: 10rem;
                        right: auto;
                        bottom: auto;
                        max-width: 32.2rem;
                    }
                    @include breakpoint(small only) {
                        justify-content: flex-end;
                    }
                    h2 {
                        font-size: 2.2rem;
                        line-height: 3.4rem;
                        text-transform: uppercase;
                        color: color(base, White);
                        font-weight: normal;
                        @include breakpoint(medium) {
                            font-size: 2.4rem;
                            line-height: 3.6rem;
                        }
                    }
                    p {
                        font-size: 1.8rem;
                        line-height: 3rem;
                    }
                    a {
                        @include transition;
                        display: block;
                        border: .2rem solid color(base, White);
                        text-transform: uppercase;
                        font-size: 1.2rem;
                        height: 4.4rem;
                        line-height: 4rem;
                        text-align: center;
                        color: color(base, White);
                        font-weight: 500;
                        margin: 1rem 0 0;
                        @include breakpoint(medium) {
                            margin: 4rem 0 0;
                            font-size: 1.4rem;
                            height: 4.8rem;
                            line-height: 4.4rem;
                            &:hover {
                                background-color: rgba(255, 255, 255, .15);
                            }
                        }
                    }
                }
            }
        }

        .innovation-block {
            .--header {
                @include default-title;
            }
            .innovation-block-container {
                max-width: 192rem;
                margin: 0 auto;
                .--slide {
                    display: flex;
                    align-items: center;
                    @include breakpoint(small only) {
                        flex-flow: column;
                    }
                    @media screen and (max-width: 1340px) {
                        img {
                            max-width: 50%;
                            @include breakpoint(small only) {
                                max-width: 100%;
                            }
                        }
                    }
                    .--content {
                        display: flex;
                        justify-content: center;
                        flex: 1;
                        .--holder {
                            box-sizing: border-box;
                            padding: 0 2rem;
                            @include breakpoint(medium) {
                                max-width: 32rem;
                                padding: 0;
                            }
                            h4 {
                                font-size: 2.4rem;
                                line-height: 3.8rem;
                                text-transform: uppercase;
                                color: color(base, Secondary);
                                font-weight: 500;
                            }
                            .--sub {
                                display: block;
                                font-size: 2.2rem;
                                line-height: 2.4rem;
                                color: color(base, Secondary);
                                margin: .5rem 0 0;
                            }
                            p {
                                font-size: 1.6rem;
                                line-height: 3rem;
                                color: color(base, Secondary);
                                margin: 2.5rem 0 0;
                            }
                            a {
                                @include alternative-button;
                                margin: 2.5rem 0 0;
                            }
                        }
                    }
                }
            }
        }

        .promotion-club {
            background: color(base, Secondary);
            img {
                width: 100%;
            }
            > .grid-container {
                text-align: center;
                padding-bottom: 3rem;
                padding-top: 3rem;
                @include breakpoint(small only) {
                    padding-bottom: 4rem;
                    padding-top: 4rem;
                }
                .--content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: .5rem;
                    color: color(base, White);
                    font-size: 1.8rem;
                    @include breakpoint(small only) {
                        flex-flow: column;
                        font-size: 1.6rem;
                    }
                    * {
                        color: color(base, White);
                    }
                    h2 {
                        font-size: 1.8rem;
                        text-transform: uppercase;
                        font-weight: 500;
                        @include breakpoint(medium) {
                            font-size: 2.4rem;
                        }
                    }
                    span {
                        font-size: 1.8rem;
                        @include breakpoint(medium) {
                            font-size: 2.4rem;
                        }
                    }
                }
                a {
                    @include transition;
                    display: inline-flex;
                    border: .2rem solid color(base, White);
                    text-transform: uppercase;
                    font-size: 1.2rem;
                    height: 4.4rem;
                    line-height: 4.8rem;
                    text-align: center;
                    color: color(base, White);
                    margin: 2.5rem 0 0;
                    padding: 0 10rem;
                    font-weight: 500;
                    @include breakpoint(medium) {
                        margin: 4rem 0 0;
                        font-size: 1.4rem;
                        height: 4.8rem;
                        &:hover {
                            background-color: rgba(255, 255, 255, .15);
                        }
                    }
                }
            }
        }
    }
}
