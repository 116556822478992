@font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("/area/web/mistral/assets/fonts/Archivo-Thin.woff2") format("woff2"),
    url("/area/web/mistral/assets/fonts/Archivo-Thin.woff") format("woff");
}

@font-face {
    font-family: 'Questrial';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/area/web/mistral/assets/fonts/Questrial-Regular.woff2") format("woff2"),
    url("/area/web/mistral/assets/fonts/Questrial-Regular.woff") format("woff");
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("/area/web/mistral/assets/fonts/Roboto-Light.woff2") format("woff2"),
    url("/area/web/mistral/assets/fonts/Roboto-Light.woff") format("woff");
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url("/area/web/mistral/assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("/area/web/mistral/assets/fonts/Roboto-Regular.woff") format("woff");
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("/area/web/mistral/assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("/area/web/mistral/assets/fonts/Roboto-Medium.woff") format("woff");
}
