.category__placeholder {
    ._placeholder {
        display: block;
        background: color(gray, Gray-150);
        position: relative;
        aspect-ratio: 161/278;
    }
}

.toolbar__container {
    position: relative;
    .catalog__toolbar:not(.--placeholder) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}
