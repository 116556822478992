.catalog__category-filter {
    border-top: .1rem solid color(gray, Gray-100);
    margin: 2rem 0 0;
    width: 100%;
    @media screen and (min-width: 1200px) {
        max-width: 85%;
    }
    .--sub-categories {
        @include breakpoint(medium) {
            border-bottom: .1rem solid color(gray, Gray-100);
        }
        padding: .5rem 0;
        > span {
            display: block;
            font-size: 1.6rem;
            text-transform: uppercase;
            line-height: 3.8rem;
            color: color(base, Secondary);
            font-weight: normal;
        }
        ol li {
            a {
                font-size: 1.4rem;
                line-height: 3rem;
                text-transform: uppercase;
                color: color(base, Secondary);
                @include breakpoint(medium) {
                    &:hover {
                        color: color(base, SecondaryHover);
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .modal__content {
        dl {
            border-bottom: .1rem solid color(gray, Gray-100);
            padding: .5rem 0;
            dt {
                font-size: 1.6rem;
                text-transform: uppercase;
                line-height: 2rem;
                padding: .8rem 0;
                color: color(base, Secondary);
                background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 4.330010) rotate(-270.000000) translate(-5.306354, -4.330010) ' points='2.44757827 -0.169989964 8.16998996 4.3284815 2.44271724 8.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right center;
                cursor: pointer;
                font-weight: 500;
                &._visible {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 5.330010) rotate(-90.000000) translate(-5.306354, -5.330010) ' points='2.44757827 0.830010036 8.16998996 5.3284815 2.44271724 9.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                }
                @include breakpoint(small only) {
                    padding: 0 1.5rem;
                    background-position: right 2.4rem center;
                    font-size: 1.4rem;
                    line-height: 3.8rem;
                }
            }
            dd {
                padding-bottom: .5rem;
                @include breakpoint(small only) {
                    padding: 1rem;
                }
                ol {
                    li label {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        gap: .5rem;
                        ._checkbox {
                            display: block;
                            width: 1.6rem;
                            height: 1.6rem;
                            border: .1rem solid color(gray, Gray-200);
                        }
                        ._title {
                            flex: 1;
                            font-size: 1.4rem;
                            line-height: 3rem;
                            text-transform: uppercase;
                            color: color(base, Secondary);
                        }
                        ._count {
                            display: none;
                        }
                        &._active {
                            ._checkbox {
                                background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='14px' viewBox='0 0 16 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='%23110047' stroke-width='3' points='1 6.9031134 5.25802431 11.1611377 15 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                                background-repeat: no-repeat;
                                background-position: center;
                                border-color: color(base, Secondary);
                                background-size: 85%;
                            }
                        }
                        @include breakpoint(medium) {
                            &:hover {
                                ._checkbox {
                                    border-color: color(gray, Gray-300);
                                    color: color(base, SecondaryHover);
                                }
                            }
                        }
                    }
                    &.--swatch {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 1.4rem;
                        padding: 1rem 0;
                        li label {
                            display: block;
                            width: 1.6rem;
                            height: 1.6rem;
                            border: .1rem solid color(gray, Gray-200);
                            @include breakpoint(small only) {
                                width: 3.4rem;
                                height: 3.4rem;
                            }
                            &._active {
                                background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='14px' viewBox='0 0 16 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(gray, Gray-100)}' stroke-width='3' points='1 6.9031134 5.25802431 11.1611377 15 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                                background-repeat: no-repeat;
                                background-position: center;
                                border-color: color(base, Secondary);
                                background-size: 85%;
                                &.--light {
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='14px' viewBox='0 0 16 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='%23110047' stroke-width='3' points='1 6.9031134 5.25802431 11.1611377 15 2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                                }
                            }
                            @include breakpoint(medium) {
                                &:hover {
                                    ._checkbox {
                                        border-color: color(gray, Gray-300);
                                    }
                                }
                            }
                        }
                    }
                }
                .price__filter {
                    padding: 1rem 0 0;
                    .vue-slider {
                        margin: 0 .7rem;
                        .vue-slider-rail {
                            background: color(gray, Gray-100);
                            border-radius: 0;
                            .vue-slider-process {
                                background: color(base, Secondary);
                            }
                            .vue-slider-dot {
                                .vue-slider-dot-handle {
                                    box-shadow: none;
                                    background: color(base, Secondary);
                                }
                            }
                        }
                        .vue-slider-dot-tooltip-inner {
                            background: color(base, Secondary);
                            border-color: color(base, Secondary);
                            .vue-slider-dot-tooltip-text {
                                color: color(base, White);
                                font-size: 1.2rem;
                            }
                        }
                    }
                    .custom__controls {
                        margin: 2rem 0 1rem;
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        span {
                            font-size: 1.4rem;
                            color: color(base, Secondary);
                            &:last-of-type {
                                margin: 0 .5rem;
                            }
                        }
                        input {
                            font-size: 1.4rem;
                            height: 4.4rem;
                        }
                    }
                }
                > a {
                    font-weight: 500;
                    font-size: 1.3rem;
                    @include breakpoint(medium) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .modal__footer {
        padding: 1rem 1.5rem;
        border-top: .1rem solid color(gray, Gray-200);
        display: flex;
        gap: 2rem;
        background: color(base, White);
        button {
            flex: 1;
            padding: 0;
            span {
                display: block;
                width: 100%;
                height: 100%;
                line-height: 4rem;
                color: color(base, White);
                font-weight: 500;
            }
            &.--transparent {
                border: .2rem solid color(base, Secondary);
                color: color(base, Secondary);
            }
        }
    }
}

@include breakpoint(small only) {
    #offCanvas-filter {
        top: 0;
        .modal__header {
            background: color(base, Secondary);
            display: flex;
            > span {
                color: color(base, White);
                text-transform: uppercase;
                font-size: 1.8rem;
                font-weight: 500;
            }
            button {
                margin-left: auto;
                span {
                    display: block;
                    color: color(base, White);
                    font-size: 3.4rem;
                }
            }
        }
    }
}
